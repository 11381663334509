import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './tree.html';
import { $events } from './../lib/events';
// import TreeEntry from './tree';

@WithRender
@Component({
    name: 'Tree',
    /*components: {
        Tree: TreeEntry
    }*/
})
export default class Tree extends Vue {
    @Prop({ required: true, default: null }) entry: any;
    @Prop({ default: false }) draggable: boolean;
    @Prop({ default: '' }) context: string;

    mounted() {}
    get getEntry() {
        return this.entry;
    }
    get getIconClass() {
        const classes: any = {};
        if (this.entry.icon) {
            classes[this.entry.icon] = true;
        }
        return classes;
    }
    selectNode(id: string) {
        this.$emit('select-node', id);
    }
    toggleNode(id: string) {
        this.$emit('toggle-node', id);
    }
    drag(e: Event) {
        if (!this.draggable) {
            return;
        }
        $events.emitDragStart(<Element>e.target, this.context, this.entry);
    }
}
