import merge from 'deepmerge';

export class Normalize {
    toKebab(value: string): string {
        if (!value || typeof value != 'string') {
            return '';
        }
        // convert all uppercase chars to -${char}
        value = value.replace(/([A-Z])/g, '-$1');
        return (
            this.toAZ(this.toLower(value))
                // convert all non a-z or 0-9 value to dash
                .replace(/[^a-z0-9_]/g, '-')
                // reduce multiple dashes to one
                .replace(/\-+/g, '-')
                // remove first dash
                .replace(/^(-)/, '')
                // remove last dash
                .replace(/(-)$/, '')
        );
    }
    toLower(value: string): string {
        if (!value || typeof value != 'string') {
            return '';
        }
        return value.trim().toLowerCase();
    }
    toAZ(value: string): string {
        if (!value || typeof value != 'string') {
            return '';
        }
        return value
            .replace(/ä/g, 'ae')
            .replace(/Ä/g, 'ae')
            .replace(/ö/g, 'oe')
            .replace(/Ö/g, 'oe')
            .replace(/ü/g, 'ue')
            .replace(/Ü/g, 'ue')
            .replace(/ß/g, 'ss');
    }
    toTreeable(data: any | any[], newProp: string = 'children', replaceProps: string[] = ['entries', 'children']): any | any[] {
        if (Array.isArray(data)) {
            return data.map((entry: any) => {
                return this.toTreeable(entry, newProp, replaceProps);
            });
        }

        // replace props
        const props = replaceProps
            .map((prop: string) => {
                if(Array.isArray(data[prop]) && data[prop].length == 0) {
                    return null;
                }
                return data[prop];
            })
            const filled = props.filter(Boolean);
        if(filled.length > 0) {
            // remove the properties
            replaceProps.forEach((prop: string)=>{
                if(!!data[prop]) {
                    delete data[prop];
                }
            })
            if(filled.length == 1) {
                data[newProp] = filled[0];
            } else {
                // merge the props into new property
                data[newProp] = merge.all(filled);
            }
        }

        // property deep replace
        const keys = Object.keys(data);
        keys.forEach((key: string) => {
            if (typeof data[key] == 'object' && !!data[key]) {
                data[key] = this.toTreeable(data[key], newProp, replaceProps);
            }
        });

        return data;
    }
}
