<section class="m-modal" v-show="showInternal" @keyup.esc="close" tabindex="0">
    <div class="m-modal__backdrop" @click="close"></div>
    <div class="m-modal__inner animated" :class="[{ 'bounceIn' : showed, 'zoomOut faster' : !showed }, cssClass]">
        <header class="m-modal__header" v-if="showHeader">
            <div class="m-flag m-flag--rev">
                <div class="m-flag__body">
                    <slot name="header"></slot>
                </div>
                <div class="m-flag__img"><button @click="close" v-if="allowClose"><i class="ri-close-line u-text--size-x1_5"></i></button></div>
            </div>
        </header>
        <div class="m-modal__content">
            <slot name="content"></slot>
        </div>
        <footer class="m-modal__footer" v-if="showFooter">
            <slot name="footer"></slot>
        </footer>
    </div>
</section>