import Vue from 'vue';
import Vuex from 'vuex';
import { NavigatorData, NavigatorSiteData, NavigatorNavigationData, NavigatorDomainData } from '../model/navigator-data';
import { Walker } from './walker';
import { NavigatorPathEntry } from '../model/path';
import { NavigatorSource } from '../model/navigator-source';
import { Normalize } from './normalize';

Vue.use(Vuex);
const normalize = new Normalize();

export default new Vuex.Store({
    state: {
        data: null as NavigatorDomainData[] | NavigatorSiteData[] | NavigatorNavigationData[],
        sources: null as NavigatorSource[],
        selectedUid: '',
        path: null as NavigatorPathEntry[],
        node: null as NavigatorData | NavigatorDomainData | NavigatorSiteData | NavigatorNavigationData,
        treeData: null as any,
        domains: null as any,
        sites: null as any
    },
    getters: {
        uid(state) {
            return state.selectedUid;
        },
        hasData(state) {
            return state?.data != null;
        },
        hasTreeData(state) {
            return state?.treeData != null;
        },
        sites(state) {
            return state?.sites;
        },
        node(state) {
            return <NavigatorSiteData>state?.node;
        },
        nodeName(state) {
            return (<NavigatorSiteData>state?.node)?.name;
        },
        nodeCode(state) {
            return normalize.toKebab((<NavigatorSiteData>state?.node)?.code);
        },
        nodeUrl(state) {
            return (<NavigatorDomainData>state?.node)?.url;
        },
        nodeLanguage(state) {
            return (<NavigatorSiteData>state?.node)?.language;
        },
        siteNavigations(state) {
            return (<NavigatorSiteData>state?.node)?.navigations?.sort((a, b)=>{
                return a.name > b.name ? 1 : -1;
            });
        },
        siteSources(state) {
            return (<NavigatorSiteData>state?.node)?.sources;
        },
        navigationItems(state) {
            return { children: (<NavigatorNavigationData>state?.node)?.navigation};
        },
        path(state) {
            let path: any[] = [];
            Walker(state.data, (node: any, parents: any[]) => {
                // console.log('walker', node.uid, state.selectedUid)
                if (node.uid == state.selectedUid) {
                    path = parents;
                    return false;
                }
                return true;
            });
            return path;
        },
        tree(state) {
            return state.treeData;
        },
        hasSources(state) {
            return state?.sources != null;
        },
        sources(state) {
            return state?.sources;
        },
    },
    mutations: {
        // addToDo(state, todoModel: ToDoModel) {
        //   state.todos.push(todoModel);
        // }
    },
});
