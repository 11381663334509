import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './accordion-entry.html';
import { $events } from './../lib/events';

@WithRender
@Component
export default class AccordionEntry extends Vue {
    @Prop({ required: true }) id: string;
    @Prop({ default: '' }) name: string;
    @Prop({ default: '' }) icon: string;
    open: boolean = false;
    mounted() {
        this.$parent.$on('open:changed', (id: string, open: boolean) => {
            if (this.id == id) {
                this.open = open;
            }
        });
    }
    toggleOpen() {
        this.$parent.$emit('open', this.id, !this.open);
    }
    get getIconClass() {
        if (!this.icon) {
            return '';
        }
        // when not starting as a remixicon ignore default styling
        if (this.icon.indexOf('ri-') != 0) {
            return this.icon;
        }
        return `u-light u-text--size-x1_5 ${this.icon}`;
    }
}
