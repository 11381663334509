import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './window.html';

@WithRender
@Component
export default class Window extends Vue {
    @Prop({ default: false }) reverse: boolean;
    barWidth: number = 300;
    handleWidth: number;

    isHandleMoving: boolean = false;
    win: Element;
    handleClickTime: number = 0;
    handleDoubleClickDirection: boolean = true;

    mounted() {
        this.win = <Element>this.$refs.window;
        const handle = <Element>this.$refs.handle;
        this.handleWidth = handle.clientWidth;
    }
    get getCssProp() {
        const prop: any = {};
        if (this.barWidth == null) {
            this.barWidth = 300;
        }
        if (this.barWidth < 0) {
            this.barWidth = 0;
        }
        prop['--c-window-bar-width'] = `${this.barWidth}px`;
        return prop;
    }
    handleMove(e: MouseEvent) {
        if (this.isHandleMoving && this.handleWidth != null) {
            const winBox = this.win.getBoundingClientRect() || { left: 0, width: 0 };

            let x = e.pageX - this.handleWidth / 2 - winBox.left;
            if (this.reverse && this.win) {
                x = winBox.width - x - this.handleWidth;
            }
            this.barWidth = x;
        }
    }
    handleMoveStart(e: Event) {
        this.isHandleMoving = true;
    }
    handleMoveStop(e: Event) {
        this.isHandleMoving = false;
    }
    handleClick(e: Event) {
        const time = new Date().getTime();
        const duration = time - this.handleClickTime;
        if (duration < 550) {
            this.handleDoubleClickDirection = this.barWidth > 50;
            this.resetBar();
        }
        this.handleClickTime = time;
    }
    resetBar() {
        if (this.handleDoubleClickDirection) {
            this.barWidth -= 25;
            if (this.barWidth > 0) {
                requestAnimationFrame(this.resetBar);
                return;
            }
            this.barWidth = 0;
            return;
        }
        if (!this.handleDoubleClickDirection) {
            this.barWidth += 25;
            if (this.barWidth < 300) {
                requestAnimationFrame(this.resetBar);
                return;
            }
            this.barWidth = 300;
            return;
        }
    }
}
