<Modal name="login" :show="true" :show-footer="true" :allow-close="false">
    <template v-slot:header>
        <div class="m-flag">
            <div class="m-flag__img">
                <Logo></Logo>
            </div>
            <div class="m-flag__body">
                {{ $t('login') }}
            </div>
        </div>
    </template>
    <template v-slot:content>
        <Textbox v-model="username" @on-enter="login" :focus="true">{{ $t('username') }}</Textbox>
        <Textbox v-model="password" @on-enter="login" type="password">{{ $t('password') }}</Textbox>
        <div v-if="error" class="u-padding-top animated bounceIn">
            <div class="m-message m-message--error">{{ $t('error-login') }}</div>
        </div>
    </template>
    <template v-slot:footer>
        <div v-if="isLoading" class="m-loader"></div>
        <Btn v-else :primary="true" :disabled="error" @click="login">{{ $t('login') }}</Btn>
    </template>
</Modal>
