import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './list-entry.html';

@WithRender
@Component
export default class ListEntry extends Vue {
    @Prop({ default: '' }) icon: string;
    @Prop({ default: false }) showButton: boolean;
    @Prop({ default: false }) clickable: boolean;
    @Prop({ default: false }) isLoading: boolean;
}
