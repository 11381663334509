<header class="c-app__header">
    <div class="m-flag">
        <div class="m-flag__img">
            <AppLogo></AppLogo>
        </div>
        <div class="m-flag__body">
            <div class="m-logo__text">NAVIGATOR</div>
        </div>
    </div>
    <div>
        <div class="m-select">
            <select :value="getCurrentLocale" @input="switchLocale">
                <option v-for="locale in locales" :value="locale">{{ $t(locale) }}</option>
            </select>
        </div>
        <Btn icon="ri-shut-down-line" @click="logout" :only-icon="true" :title="$t('logout')"></Btn>
        <!-- <button class="m-button m-button--primary" @click="showPublish = true"><i class="ri-upload-cloud-2-line"></i> {{ $t('publish') }}</button> -->
        <!-- <div class="m-flag">
            <div class="m-flag__img">
                <i class="ri-user-line u-text--size-x1_5"></i>
            </div>
            <div class="m-flag__body">
                Patrick
            </div>
        </div> -->
        <Modal :show="showPublish" :show-footer="true" @close="showPublish = false">
            <template v-slot:header>{{ $t('publish') }}</template>
            <template v-slot:content>[Show here the differences and load the current state from server]</template>
            <template v-slot:footer>
                <Btn @click="showPublish = false">{{ $t('cancel') }}</Btn>
                <Btn @click="publish" :primary="true">{{ $t('publish') }}</Btn>
            </template>
        </Modal>
    </div>
</header>
