<div class="u-padding-top--x_5" :class="{ 'c-tree c-tree--small c-tree--list-entry': entry && entry.uid}">
    <div
        class="m-list-entry m-flag m-drag-clone__master"
        draggable="true"
        v-if="entry && entry.uid"
        :class="{ 'm-list-entry--avoid-child-events-inactive': entry.uniq == dragUniq}"
    >
        <div class="m-flag__img" @mousedown="entryDrag($event, entry, parents)">
            <i class="ri-drag-move-line u-text--size-x2"></i>
        </div>
        <div class="m-flag__body m-flag m-flag--rev">
            <div class="m-flag__body">
                <!-- <div class="u-light u-text--size-x_75">{{entry.uniq}}</div>
                <div class="u-light u-text--size-x_75">{{parents}}</div> -->
                {{entry.name}} <span class="u-light">{{entry.code}}</span>
                <div class="u-light u-text--size-x_75 u-padding-top--x_25">
                    <a :href="entry.identifier">{{entry.identifier}}</a>
                </div>
            </div>
            <div class="m-flag__img m-list-entry__buttons">
                <Btn @click="entryEdit(entry)" icon="ri-pencil-line" :only-icon="true" :title="$i18n.t('edit')"></Btn>
                <Btn @click="requestEntryDelete(entry, parents)" icon="ri-delete-bin-line" :only-icon="true" :title="$i18n.t('delete')"></Btn>
            </div>
        </div>
    </div>
    <div :class="{ 'u-padding-left--x2': !isRoot }" v-if="entry && hasChildren">
        <ListEntryTree
            ref="children"
            v-for="child in entry.children"
            :key="child.uniq"
            :entry="child"
            :parents="childParents"
            :drag-uid="dragUid"
            :drag-uniq="dragUniq"
            @entry-update="entryUpdate"
            @entry-drag="entryDrag"
            @entry-edit="entryEdit"
            @entry-delete="entryDelete"
            @delete-question="deleteQuestion"
        ></ListEntryTree>
    </div>
</div>
