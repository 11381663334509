import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './breadcrumbs.html';
import { $events } from '../lib/events';

@WithRender
@Component
export default class AppBreadcrumbs extends Vue {
    @Prop({ default: null }) breadcrumbs: any[] | null;

    selectNode(uid: string) {
        $events.emitNodeSelect(uid);
    }
}
