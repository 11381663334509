import { Component, Vue, Watch } from 'vue-property-decorator';
import WithRender from './login.html';
import Logo from './logo';
import Modal from './../components/modal';
import Btn from './../components/btn';
import Textbox from './../components/form/textbox';
import { API } from '../lib/api';
import { $events } from '../lib/events';

@WithRender
@Component({
    components: { Modal, Textbox, Logo, Btn },
})
export class AppLogin extends Vue {
    username = '';
    password = '';
    isLoading = false;
    error = false;
    api = new API('login');
    async login() {
        if (this.isLoading || this.error) {
            return;
        }

        if (!this.username || !this.password) {
            this.error = true;
            return;
        }
        this.isLoading = true;
        const response = await this.api.post('/oauth', {
            username: this.username,
            password: this.password,
            client_id: 'navigator_frontend',
            response_type: 'token',
            grant_type: 'password',
        });
        this.isLoading = false;
        if (!response) {
            this.error = true;
            return;
        }
        if (response.status == 200) {
            localStorage.setItem('app:oauth', JSON.stringify(response.data));
            localStorage.setItem('app:oauth:last', new Date().getTime() + '');
            $events.emitAuthGet(response.data);
            $events.emitLogin();
            // set logout timer for the refresh
            const expires_in = (response.data?.expires_in || 3600) * 1000;
            setTimeout(() => {
                $events.emitLogout();
            }, expires_in);
            return;
        }
        console.warn(response);
    }
    @Watch('error')
    errorChanged(value: boolean) {
        if (!!value) {
            setTimeout(() => {
                this.error = false;
            }, 3000);
        }
    }
}
