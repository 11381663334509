import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './dropzone.html';
import { $events } from '../lib/events';

@WithRender
@Component
export class Dropzone extends Vue {
    @Prop({ default: '' }) cssClass: string;
    dragOver: boolean = false;
    mounted() {
        (<HTMLElement>this.$refs.dropzone).addEventListener('dragover', ()=>{
           this.dragOver = true;
        });
        (<HTMLElement>this.$refs.dropzone).addEventListener('dragleave', ()=>{
            this.dragOver = false;
        });
        $events.onDragStart((source: Element, context: string, data: any) => {});
        $events.onDragEnd((target: Element, source: Element, context: string, data: any) => {
            if (target == this.$refs.dropzone) {
                this.$emit('drop', target, source, context, data);
            }
        });
    }
    get getCssClass() {
        const classes = [this.cssClass];
        if(this.dragOver) {
            classes.push('c-dropzone--over');
        }
        return classes;
    }
}
