<div class="c-tree" v-if="getEntry">
    <div class="c-tree__entry">
        <i
            class="c-tree__state"
            v-if="getEntry.children && getEntry.children.length > 0"
            :class="{ 'ri-add-box-fill': !getEntry.open, 'ri-checkbox-indeterminate-fill': getEntry.open }"
            @click="toggleNode(getEntry.uid)"
        ></i>
        <div class="c-tree__item" :class="{'c-tree--selected': getEntry.selected }" @click="selectNode(getEntry.uid)" @mousedown="drag" :draggable="draggable">
            <i :class="getIconClass" class="c-tree__icon" :title="getEntry.type"></i>
            <span class="c-tree__text">{{getEntry.name}} <span class="u-light">{{getEntry.code}}</span></span>
        </div>
    </div>
    <div class="c-tree__children" v-if="getEntry.children && getEntry.open">
        <Tree v-for="child of getEntry.children" :entry="child" @toggle-node="toggleNode" @select-node="selectNode" :draggable="draggable" :context="context"></Tree>
    </div>
</div>
