<div class="c-search" :class="{ 'c-search--open': open }">
    <label class="c-search__label" :for="uniq">{{ $t('available-sources') }}</label>
    <div class="c-search__box m-flag">
        <label class="c-search__icon m-flag__img" :for="uniq"><i class="ri-search-line"></i></label>
        <div class="c-search__input m-flag__body">
            <input @focus="focus" @blur="blur" :id="uniq" :value="val" @input="change" />
        </div>
    </div>
    <!-- <div class="c-search__preview m-flag m-flag--rev" v-show="preview">
        <div class="m-flag__body">{{preview}}</div>
        <div class="m-flag__img c-search__clear" @click="clear"><i class="ri-close-line"></i></div>
    </div> -->
</div>