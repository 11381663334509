import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './domain.html';
import { $events } from '../lib/events';
import Modal from '../components/modal';
import Accordion from '../components/accordion';
import AccordionEntry from '../components/accordion-entry';
import Btn from '../components/btn';
import ListEntry from '../components/list-entry';
import Textbox from '../components/form/textbox';
import { API } from '../lib/api';
import { MessageModel, MessageTypesEnum } from '../components/messages';
import { NavigatorSiteData } from '../model/navigator-data';
import { Normalize } from '../lib/normalize';

@WithRender
@Component({
    components: {
        Modal,
        Textbox,
        Accordion,
        AccordionEntry,
        Btn,
        ListEntry,
    },
})
export class DomainView extends Vue {
    showAddSite: boolean = false;
    showOptions: boolean = false;
    deferrer: any = null;
    api = new API('domain');
    newSite: NavigatorSiteData | any = {
        name: '',
        code: '',
        language: '',
        domain: '',
    };
    normalize = new Normalize();
    mounted() {}
    changeName(value: string) {
        // this.$store.state.data.domain = value;
        // $events.emitNodeSelect( this.$store.state.data.uid);
        $events.emitNodePropertyChange(this.$store.getters.uid, 'name', value);
        this.deferredSave();
    }
    changeUrl(value: string) {
        $events.emitNodePropertyChange(this.$store.getters.uid, 'url', value);
        this.deferredSave();
    }
    deferredSave() {
        if (!!this.deferrer) {
            clearTimeout(this.deferrer);
        }
        const state = this.$store.getters.node;
        this.deferrer = setTimeout(async () => {
            const domain = {
                name: state.name,
                url: state.url,
            };
            const result = await this.api.put(`/domain/${state.uid}`, domain);
            const msg = new MessageModel();
            if (!!result && result.status == 200) {
                msg.setType(MessageTypesEnum.Success);
                msg.setContent(this.$i18n.t('success-save'));
            } else {
                msg.setType(MessageTypesEnum.Error);
                msg.setContent(this.$i18n.t('error-save'));
            }
            $events.emitMessage(msg);
        }, __SAVE_DELAY__);
    }
    initAccordion() {
        (<Vue>this.$refs.accordion).$emit('register', 'domain', true);
        (<Vue>this.$refs.accordion).$emit('register', 'sites', true);
    }
    openSite(uid: string) {
        $events.emitNodeSelect(uid);
    }

    changeNewSiteName(value: string) {
        if (this.newSite.code == this.normalize.toKebab(this.newSite.name) || !this.newSite.code) {
            this.newSite.code = this.normalize.toKebab(value);
        }
        this.newSite.name = value;
    }
    changeNewSiteCode(value: string) {
        this.newSite.code = this.normalize.toKebab(value);
    }
    async addSite() {
        this.newSite.domain = this.$store.getters.uid;
        const response = await this.api.post('/site', this.newSite);

        if (!response || !response.data || response.status >= 300) {
            $events.emitMessage(new MessageModel(MessageTypesEnum.Error, this.$i18n.t('error-save')));
            return;
        }
        this.$store.state.node.sites.push(response.data);
        this.$store.state.sites.push(response.data);
        $events.emitMessage(new MessageModel(MessageTypesEnum.Success, this.$i18n.t('success-save')));
        $events.emitDataGet(this.$store.state.data);
        setTimeout(async () => {
            $events.emitNodeSelect(response.data.uid);
        }, 250);
        this.closeAddSite();
    }
    closeAddSite() {
        this.showAddSite = false;
        this.newSite = {
            name: '',
            code: '',
            language: '',
            domain: '',
        };
    }
}
