import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './header.html';
import { $events } from './../lib/events';
import AppLogo from './logo';
import Modal from './../components/modal';
import Btn from './../components/btn';

@WithRender
@Component({
    components: {
        AppLogo,
        Modal,
        Btn,
    },
})
export default class AppHeader extends Vue {
    showPublish: boolean = false;
    locales = ['de', 'en'];
    mounted() {}

    publish() {
        alert('@TODO publish');
        this.showPublish = false;
    }

    switchLocale(e: Event) {
        if (e && e.target) {
            const value = (<HTMLSelectElement>e.target).value;
            if (!value) {
                return;
            }
            $events.emitLocaleSwitch(value.toLowerCase());
        }
    }
    get getCurrentLocale() {
        return this.$i18n.locale;
    }
    logout() {
        $events.emitLogout();
    }
}
