import { Component, Vue } from 'vue-property-decorator';
import WithRender from './app.html';
import { $events } from './../lib/events';
import SiteTree from './../components/site-tree';
import Window from './../components/window';
import Modal from './../components/modal';
import Textbox from './../components/form/textbox';
import Btn from './../components/btn';
import { Messages, MessageModel, MessageTypesEnum } from './../components/messages';
import AppHeader from './header';
import AppBreadcrumbs from './breadcrumbs';
import { DomainView } from '../views/domain';
import { SiteView } from '../views/site';
import { NavigationView } from '../views/navigation';
import { Walker, TreeWalker } from '../lib/walker';
import { API } from '../lib/api';
import { Normalize } from '../lib/normalize';
import { NavigatorSource } from '../model/navigator-source';
import { NavigatorNavigationData, NavigatorSiteData, NavigatorDomainData, NavigatorSourceData } from '../model/navigator-data';
import { AppLogin } from './login';
import { clone } from './../helper/clone';

@WithRender
@Component({
    components: {
        SiteTree,
        AppHeader,
        AppBreadcrumbs,
        DomainView,
        SiteView,
        NavigationView,
        Window,
        Messages,
        Modal,
        Textbox,
        Btn,
        AppLogin,
    },
})
export default class App extends Vue {
    views: any = [
        {
            key: 'none',
            visible: true,
        },
        {
            key: 'domain',
            visible: false,
        },
        {
            key: 'site',
            visible: false,
        },
        {
            key: 'navigation',
            visible: false,
        },
    ];
    data: any;
    path: any = [];
    isDragging: boolean = false;
    draggingData: { source: Element; context: string; data: any } | null = null;
    api = new API('app');
    domain: any = {
        name: '',
        url: '',
    };
    showAddDomain: boolean = false;
    site: any = {
        name: '',
        code: '',
        language: 'de',
        domain: '',
    };
    showAddSite: boolean = false;
    normalize = new Normalize();
    loaded = {
        sites: false,
        sources: false,
        navigations: false,
    };
    auth: string = null;
    showLogin: boolean = true;

    mounted() {
        this.bindEvents();
        this.loadAuth();

        $events.emitAuthGet(this.auth);

        if (this.showLogin) {
            $events.emitAppReady();
        } else {
            this.startLoadingData();
            // add logout timer
            const lastLogin = +localStorage.getItem('app:oauth:last') || 0;
            const now = new Date().getTime();
            const loggedInUntil = lastLogin + (<any>this.auth).expires_in * 1000;
            if (loggedInUntil <= now) {
                $events.emitLogout();
            } else {
                const timer = loggedInUntil - now;
                setTimeout(() => {
                    $events.emitLogout();
                }, timer);
            }
        }

        // this.$el.addEventListener('drop', ()=>{
        //     if(this.isDragging) {
        //         console.log('drop')
        //         this.isDragging = false;
        //         $events.emitDragEnd, this.draggingData.target(this.draggingData.context)
        //     }
        // })
        // this.$el.addEventListener('dragover', ()=>{
        //     console.log('dragover')

        // })
        // this.$el.addEventListener('dragleave', ()=>{
        //     console.log('dragleave')
        // })
        // By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element
    }
    bindEvents() {
        $events.onViewShow((key: string) => {
            this.views.forEach((view: any) => {
                view.visible = view.key == key;
            });
        });
        // drag & drop
        $events.onDragStart((source: Element, context: string, data: any) => {
            this.isDragging = true;
            const simpleData = JSON.parse(JSON.stringify(data));
            this.draggingData = { source, context, data: simpleData };
        });
        $events.onDragEnd((target: Element, source: Element, context: string, data: any) => {
            this.isDragging = false;
        });

        // token handling
        $events.onAuthInvalidate(() => {
            localStorage.removeItem('app:oauth');
            this.auth = null;
            this.showLogin = true;
            $events.emitAuthGet(null);
        });

        $events.onAuthAsk(() => {
            $events.emitAuthGet(this.auth);
        });

        $events.onLoginForce(() => {
            $events.emitAppReady();
        });
        $events.onLogin(() => {
            this.loadAuth();
            this.startLoadingData();
        });
        $events.onLogout(() => {
            // invalidate login
            $events.emitAuthInvalidate();
            // show logout screen
            $events.emitForceLogin();
            // show logout message
            const message = new MessageModel(MessageTypesEnum.Error, this.$i18n.t('logged-out'));
            $events.emitMessage(message);
        });
        document.addEventListener('dragover', (e: Event) => {
            e.preventDefault();
        });
        document.addEventListener('drop', (e: Event) => {
            if (this.isDragging) {
                this.isDragging = false;
                $events.emitDragEnd(<Element>e.target, this.draggingData.source, this.draggingData.context, this.draggingData.data);
                this.draggingData = null;
            }
        });

        $events.onDataGet((data: any) => {
            this.data = data;
        });
        // update path based on the selected node
        $events.onNodeSelect((uid: string) => {
            this.selectNode(uid);
        });
        $events.onNodePropertyChange((uid: string, prop: string, value: any) => {
            Walker(this.$store.state.data, (node: any, parents: any[]) => {
                if (node.uid == uid) {
                    node[prop] = value;
                }
            });
            $events.emitNodePropertyChangeDone(uid, prop, value);
        });
        $events.onNodeUniqPropertyChange((uniq: string, prop: string, value: any) => {
            let uid = '';
            Walker(this.$store.state.data, (node: any, parents: any[]) => {
                if (node.uniq == uniq) {
                    node[prop] = value;
                    uid = node.uid;
                }
            });
            $events.emitNodePropertyChangeDone(uid, prop, value);
        });
        $events.onLocaleSwitch((locale: string) => {
            this.switchLocale(locale);
        });
        $events.onDataAsk(() => {
            if (this.$store.state.data) {
                $events.emitDataGet(this.$store.state.data);
            }
        });
    }
    loadAuth() {
        const auth = localStorage.getItem('app:oauth');
        this.auth = !!auth ? JSON.parse(auth) : null;
        // login/logout processing
        this.showLogin = !this.auth;
    }
    switchLocale(locale: string) {
        setTimeout(() => {
            const msg = new MessageModel(
                MessageTypesEnum.Success,
                this.$i18n
                    .t('switch-locale-message', {
                        locale: this.$i18n.t(locale),
                    })
                    .toString()
            );
            $events.emitMessage(msg);
        }, 250);
    }
    finalizeStartup() {
        if (!!this.loaded.sites && !!this.loaded.sources && !!this.loaded.navigations) {
            let domainToSelect: string | null = null;
            // all data is loaded build structure
            const data = this.$store.state.domains
                .map((domain: NavigatorDomainData) => {
                    if (!domainToSelect) {
                        domainToSelect = domain.uid;
                    }
                    // enhance sites
                    const domainSiteUids = Object.keys(domain.sites);
                    delete domain.sites;
                    domain.sites = domainSiteUids
                        .map((siteUid) => {
                            const site = this.$store.state.sites.find((s: NavigatorSiteData) => {
                                return s.uid == siteUid;
                            });
                            if (site) {
                                // enhance navigations
                                const siteNavigationUids = Object.keys(site.navigations);
                                delete site.navigations;
                                site.navigations = siteNavigationUids
                                    .map((navigationUid) => {
                                        const navigation = this.$store.state.navigations.find((m: NavigatorNavigationData) => {
                                            return m.uid == navigationUid;
                                        });
                                        if (navigation) {
                                            return navigation;
                                        }
                                        return null;
                                    })
                                    .filter(Boolean);
                                // enhance sources
                                site.sources = site.sources
                                    .map(
                                        (sourceUid: string): NavigatorSourceData => {
                                            let source = null;
                                            TreeWalker(this.$store.state.sources, (s: NavigatorSourceData) => {
                                                if (s.uid == sourceUid) {
                                                    source = s;
                                                    return false;
                                                }
                                                return true;
                                            });
                                            // const source = this.$store.state.sources.find((s: NavigatorSourceData) => {
                                            //     return s.uid == sourceUid;
                                            // });
                                            if (source) {
                                                return source;
                                            }
                                            return null;
                                        }
                                    )
                                    .filter(Boolean);

                                return site;
                            }
                            return null;
                        })
                        .filter(Boolean);
                    return domain;
                })
                .filter(Boolean);

            console.log('data', data);
            this.$store.state.data = data;
            $events.emitDataGet(data);
            // select first domain
            if (!!domainToSelect && !location.hash) {
                $events.emitNodeSelect(domainToSelect);
            }
            $events.emitAppReady();
        }
    }
    startLoadingData() {
        this.api.get('/domain').then((response) => {
            if (!response) {
                $events.emitAuthInvalidate();
                return;
            }
            // show wizard
            if (!!response.data && response.data.length == 0) {
                this.showAddDomain = true;
                return;
            }
            this.$store.state.domains = response.data;
            this.loadData();
        });
    }
    async loadData() {
        if (this.showLogin) {
            return;
        }
        // load site when not available
        if (!this.$store.state.site) {
            const siteResponse = await this.api.get('/site');
            if (!siteResponse || !siteResponse.data || siteResponse.data.length == 0) {
                this.showAddSite = true;
                return;
            }
            this.$store.state.sites = siteResponse.data;
            this.loaded.sites = true;
            this.finalizeStartup();
        } else {
            this.loaded.sites = true;
        }

        this.api.get('/source').then((response) => {
            if (!response) {
                return;
            }
            const sources: NavigatorSource[] = this.normalize.toTreeable(response.data);
            this.$store.state.sources = sources;
            this.loaded.sources = true;
            this.finalizeStartup();
        });

        this.api.get('/navigation').then((response) => {
            if (!response) {
                return;
            }
            const navigations: NavigatorNavigationData[] = response.data;
            this.$store.state.navigations = navigations;
            this.loaded.navigations = true;
            this.finalizeStartup();
        });
    }
    selectNode(uid: string) {
        console.log('select node', uid);
        // set the selected uid
        this.$store.state.selectedUid = uid;
        // set selected node
        let selectedNode = null;
        Walker(this.$store.state.data, (node: any, parents: any[]) => {
            if (node.uid == uid) {
                selectedNode = node;
            }
        });
        // go to domain when the uid can not be found
        if (this.$store.state.data && !selectedNode) {
            $events.emitMessage(new MessageModel(MessageTypesEnum.Warning, this.$i18n.t('node-not-found').toString()));
            setTimeout(() => {
                $events.emitNodeSelect(this.$store.state.data[0].uid);
            }, 250);
        }
        this.$store.state.node = selectedNode;

        // get breadcrumb/path
        const path = this.$store.getters.path;
        // switch the view based on the prop/type
        if (!path.length) {
            return;
        }
        const currentPathStep = path[path.length - 1];
        let view = 'none';
        switch (currentPathStep.prop) {
            case '':
                view = 'domain';
                break;
            case 'domain':
                view = currentPathStep.prop;
                break;
            case 'sites':
                view = 'site';
                break;
            case 'navigations':
                view = 'navigation';
                break;
        }
        $events.emitViewShow(view);

        // update the url by adding a new state when the current state is not the item to switch to
        const url = `#/uid/${uid}`;
        if (!history.state || history.state.uid != uid) {
            history.pushState({ uid: uid }, view, url);
        }
    }
    async addDomain() {
        if (!this.isAddDomainValid) {
            return;
        }
        this.closeAddDomain();
        const response = await this.api.post('/domain', this.domain);
        if (!response || !response.data || response.status >= 300) {
            $events.emitMessage(new MessageModel(MessageTypesEnum.Error, this.$i18n.t('error-save')));
            this.showAddDomain = true;
            return;
        }
        this.$store.state.domains = [response.data];
        // create site
        this.showAddSite = true;
    }
    closeAddDomain() {
        this.showAddDomain = false;
    }
    async addSite() {
        if (!this.isAddSiteValid) {
            return;
        }
        this.closeAddSite();
        console.log(clone(this.$store.state));
        this.site.domain = this.$store.state.domains[0].uid;
        const response = await this.api.post('/site', this.site);
        if (!response || !response.data || response.status >= 300) {
            $events.emitMessage(new MessageModel(MessageTypesEnum.Error, this.$i18n.t('error-save')));
            this.showAddSite = true;
            return;
        }
        // do not add to the state because it gets loaded afterwards
        // add to domains as object to simularte the response from the api for the domain
        const siteData: any = {};
        siteData[response.data.uid] = response.data.uid;
        this.$store.state.domains[0].sites = siteData;
        // create site
        this.showAddSite = false;
        this.loadData();
    }
    closeAddSite() {
        this.showAddDomain = false;
    }
    changeSiteName(value: string) {
        if (this.site.code == this.normalize.toKebab(this.site.name) || !this.site.code) {
            this.site.code = this.normalize.toKebab(value);
        }
        this.site.name = value;
    }
    changeSiteCode(value: string) {
        this.site.code = this.normalize.toKebab(value);
    }
    get isAddDomainValid() {
        return !!this.domain.name && !!this.domain.url;
    }
    get isAddSiteValid() {
        return !!this.site.name && !!this.site.code && !!this.site.language;
    }
    get isNoneViewVisible() {
        return (
            (<any>this.views).find((view: any) => {
                return view.key == 'none' && view.visible == true;
            }) != null
        );
    }
    get isDomainViewVisible() {
        return (
            (<any>this.views).find((view: any) => {
                return view.key == 'domain' && view.visible == true;
            }) != null
        );
    }
    get isSiteViewVisible() {
        return (
            (<any>this.views).find((view: any) => {
                return view.key == 'site' && view.visible == true;
            }) != null
        );
    }
    get isNavigationViewVisible() {
        return (
            (<any>this.views).find((view: any) => {
                return view.key == 'navigation' && view.visible == true;
            }) != null
        );
    }
}
