import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './btn.html';

@WithRender
@Component
export default class Btn extends Vue {
    @Prop({ default: false }) primary: boolean;
    @Prop({ default: false }) offset: boolean;
    @Prop({ default: false }) onlyIcon: boolean;
    @Prop({ default: '' }) icon: string;
    @Prop({ default: false }) disabled: boolean;
}
