import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './list-entry-tree.html';
import Btn from './btn';
import { clone } from '../helper/clone';

@WithRender
@Component({
    name: 'ListEntryTree',
    components: {
        Btn,
    },
})
export default class ListEntryTree extends Vue {
    @Prop({ required: true }) entry: any;
    @Prop({ required: true }) parents: any[];
    @Prop({ default: null }) dragUid: string;
    @Prop({ default: null }) dragUniq: string;
    mounted() {
        // this.$on('entry-drag', (e: Event, entry: any) => {
        //     this.$emit('entry-drag', e, entry);
        // });
        // this.$on('entry-edit', (e: Event, entry: any) => {
        //     this.$emit('entry-edit', e, entry);
        // });
        this.$on('delete-allowed', (entry: any, parents: string[]) => {
            if (entry && entry.uniq == this.entry.uniq) {
                this.entryDelete(entry, parents);
                return;
            }
            // send event to children
            const children = <Vue[]>this.$refs.children || [];

            children.forEach((child: Vue) => {
                child.$emit('delete-allowed', entry, parents);
            });
        });
    }

    get childParents() {
        if (!!this.parents && this.entry.uniq) {
            return [...this.parents, this.entry.uniq];
        }
        return [];
    }

    get isRoot(): boolean {
        return !this.entry.uid;
    }
    get hasChildren(): boolean {
        return this.entry.children && this.entry.children.length > 0;
    }
    entryDrag(e: Event, entry: any, parents: string[]) {
        this.$emit('entry-drag', e, entry, parents);
    }
    entryEdit(entry: any) {
        this.$emit('entry-edit', entry);
    }

    entryDelete(entry: any, parents: string[]) {
        // check if the entry to delete is a child entry
        const isParent = parents.join(',') === this.childParents.join(',');
        const hasGivenEntryAsChild = this.hasChildren && !!this.entry.children.find((c: any) => c.uniq == entry.uniq);
        //parents.length > 0 && parents[parents.length - 1] == this.entry.uid
        if (isParent && hasGivenEntryAsChild) {
            const current = clone(this.entry);
            current.children = current.children.filter((c: any) => c.uniq != entry.uniq);
            this.$emit('entry-update', current);
            return;
        }
        this.$emit('entry-delete', entry, parents);
        // this.$store.getters.navigationItems = this.$store.getters.navigationItems.filter((item: any, idx: number)=>{
        //     return item.uid != entry.uid && index != idx;
        // })
    }
    entryUpdate(childEntry: any) {
        const current = clone(this.entry);
        // update the child of the current entry
        if (current.children) {
            const uids = current.children.map((node: any) => {
                return node.uid;
            });
            if (uids.indexOf(childEntry.uid) > -1) {
                // replace the child with the given entry
                current.children = current.children.map((node: any) => {
                    if (node.uniq == childEntry.uniq) {
                        return childEntry;
                    }
                    return node;
                });
            } else {
                current.children.push(childEntry);
            }
        }
        this.$emit('entry-update', current);
    }
    requestEntryDelete() {
        this.$emit('delete-question', this.entry, this.parents);
    }
    deleteQuestion(entry: any, parents: string[]) {
        this.$emit('delete-question', entry, parents);
    }
}
