<main class="c-app_container">
    <app-login v-if="showLogin"></app-login>
    <div v-if="!showLogin" class="c-app" :class="{ 'c-app--is-dragging': isDragging}">
        <app-header></app-header>
        <div class="c-app__body">
            <Window>
                <template v-slot:bar>
                    <site-tree></site-tree>
                </template>
                <template v-slot:main>
                    <div class="m-rows">
                        <app-breadcrumbs :breadcrumbs="$store.getters.path"></app-breadcrumbs>
                        <div class="m-rows__row m-rows--fill">
                            <div v-if="isNoneViewVisible" class="u-align-center u-padding">
                                <i class="ri-compass-line u-text--size-x4 u-light"></i>
                            </div>
                            <domain-view v-if="isDomainViewVisible"></domain-view>
                            <site-view v-if="isSiteViewVisible"></site-view>
                            <navigation-view v-if="isNavigationViewVisible"></navigation-view>
                        </div>
                    </div>
                </template>
            </Window>
        </div>
    </div>

    <messages></messages>

    <Modal :show="showAddDomain" :show-footer="true" :allow-close="false">
        <template v-slot:header>{{ $t('add-domain') }}</template>
        <template v-slot:content>
            <Textbox v-model="domain.name" @on-enter="addDomain" :focus="showAddDomain">{{ $t('name') }}</Textbox>
            <Textbox v-model="domain.url"  @on-enter="addDomain">{{ $t('url') }}</Textbox>
        </template>
        <template v-slot:footer>
            <Btn @click="addDomain" :primary="true" :disabled="!isAddDomainValid">{{ $t('add') }}</Btn>
        </template>
    </Modal>

    <Modal :show="showAddSite" :show-footer="true" :allow-close="false">
        <template v-slot:header>{{ $t('add-site') }}</template>
        <template v-slot:content>
            <Textbox :value="site.name" @input="changeSiteName" @on-enter="addSite" :focus="showAddSite">{{ $t('name') }}</Textbox>
            <Textbox :value="normalize.toKebab(site.code)" @input="changeSiteCode" icon="ri-magic-line" @on-enter="addSite">{{ $t('code') }}</Textbox>
            <Textbox v-model="site.language" @on-enter="addSite">{{ $t('language') }}</Textbox>
        </template>
        <template v-slot:footer>
            <Btn @click="addSite" :primary="true" :disabled="!isAddSiteValid">{{ $t('add') }}</Btn>
        </template>
    </Modal>
</main>
