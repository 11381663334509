import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './textbox.html';
import Id from './../../lib/id';

@WithRender
@Component
export default class Textbox extends Vue {
    @Prop({ default: '' }) value: string;
    @Prop({ default: '' }) icon: string;
    @Prop({ default: false }) focus: boolean;
    @Prop({ default: 'text' }) type: string;
    uniq: string = Id();
    mounted() {}
    @Watch('focus')
    changeFocus(value: boolean) {
        if(!!value) {
            document.getElementById(this.uniq).focus();
        }
    }
    change(e: any) {
        this.$emit('input', e.target.value);
    }
    onEnter() {
        this.$emit('on-enter');
    }
}
