import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './accordion.html';
import { $events } from './../lib/events';

@WithRender
@Component
export default class Accordion extends Vue {
    @Prop({ default: false }) single: boolean;
    entries: AccordionListEntry[] = [];

    mounted() {
        this.$on('register', (id: string, open: boolean) => {
            const found = this.entries.find((entry: any) => {
                if (entry.id == id) {
                    // update the open state when the entry already exists
                    entry.open = open;
                    return true;
                }
                return false;
            });
            if (!found) {
                this.entries.push({
                    id,
                    open,
                });
            }
            this.$emit('open:changed', id, open);
        });
        this.$on('open', (id: string, open: string) => {
            // check if other accordion is open in single mode otherwise ignore closing
            if (this.single && !open) {
                const openedEntries = this.entries.filter((entry: any) => {
                    return entry.open && entry.id != id;
                });
                if (!openedEntries.length) {
                    return;
                }
            }
            // change the state of the entry
            this.$emit('open:changed', id, open);
            this.entries.forEach((entry: any) => {
                if (entry.id == id) {
                    entry.open = open;
                    return;
                }
                // when single mode then close all other entries
                if (this.single && open) {
                    entry.open = false;
                    this.$emit('open:changed', entry.id, false);
                }
            });
        });
        this.$emit('mounted');
    }
}
export class AccordionListEntry {
    id: string;
    open: boolean;
}
