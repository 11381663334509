import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './modal.html';

@WithRender
@Component
export default class Modal extends Vue {
    @Prop({ default: '' }) name: string;
    @Prop({ default: false }) show: boolean;
    @Prop({ default: true }) showHeader: boolean;
    @Prop({ default: false }) showFooter: boolean;
    @Prop({ default: true }) allowClose: boolean;
    @Prop({ default: '' }) cssClass: string;

    showed: boolean = true;
    showInternal: boolean = false;
    close(forceClose: boolean = false) {
        if(!this.allowClose && forceClose !== true) {
            return;
        }
        this.showed = false;
        setTimeout(() => {
            this.$emit('close', false, this.name);
            this.showInternal = false;
        }, 500);
    }
    @Watch('show') 
    watchShow(value: boolean) {
        if (value) {
            this.showed = true;
            this.showInternal = true;
        } else {
            this.close(true);
        }
    }
    mounted() {
        if(!!this.show) {
            this.showInternal = true;
        }
    }
}
