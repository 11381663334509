import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './navigation.html';
import { $events } from '../lib/events';
import Window from '../components/window';
import Modal from '../components/modal';
import Tree from '../components/tree';
import Accordion from '../components/accordion';
import AccordionEntry from '../components/accordion-entry';
import Btn from '../components/btn';
import ListEntryTree from '../components/list-entry-tree';
import Textbox from '../components/form/textbox';
import { API } from '../lib/api';
import { MessageModel, MessageTypesEnum } from '../components/messages';
import { Walker, TreeWalker } from '../lib/walker';
import { NavigatorNavigationData, NavigatorSiteData, NavigatorSourceData } from '../model/navigator-data';
import { Dropzone } from '../components/dropzone';
import { NavigatorSource } from '../model/navigator-source';
import { TreeHelper } from '../helper/tree';
import { Normalize } from '../lib/normalize';
import { clone } from './../helper/clone';
import { v4 as uuidv4 } from 'uuid';

@WithRender
@Component({
    components: {
        Modal,
        Textbox,
        Accordion,
        AccordionEntry,
        Btn,
        ListEntryTree,
        Window,
        Tree,
        Dropzone,
    },
})
export class NavigationView extends Vue {
    showDelete: boolean = false;
    api = new API('navigation');
    sources: any;
    site: NavigatorSiteData = null;
    sourceTree: any = [];
    treeHelper = new TreeHelper();
    normalize = new Normalize();
    deferrer: any = null;
    isDragging = false;
    dragUid: string = null;
    dragUniq: string = null;
    dragEntry: any = null;
    dragEntryTarget: any = null;
    dragEntryParents: any = null;
    dragMadeChanges: boolean = false;
    entryParents: string[] = []; // base for all navigation child entries
    showEntryDelete: boolean = false;
    showEntryEdit: boolean = false;
    showEntrySource: boolean = false;
    selectedEntry: any = null;
    selectedEntryParents: string[] = null;
    dragMasterSettings: any = {};
    dragStartCoords: any = {};
    notPossibleTimer: any = null;
    sourceParents: any = {};

    created() {
        // when switches from navigation to navigation
        $events.onNodeSelect(() => {
            const path = this.$store.getters.path;
            if (path && path[path.length - 1].prop == 'navigations') {
                this.setup();
            }
        });
    }
    mounted() {
        this.setup();
    }
    setup() {
        // create new treeHelper
        this.treeHelper = new TreeHelper();
        this.sourceTree = [];
        const parent = this.getParentSite();
        this.site = null;
        let siteNode: any = null;
        // console.log(parent);
        Walker(this.$store.state.data, (node: any) => {
            if (node.uid == parent.uid) {
                siteNode = clone(node);
            }
        });
        this.site = siteNode;
        // console.log(this.site);

        const uids = this.treeHelper.getSourceTree(<any>this.site.sources).map((source: any) => {
            source.open = true;

            // needs to be cloned, because vue adds getter and setter for every property
            // and when the getter/setter are existing already and
            // new properties has been added
            // no new getter and setter will be added for the new property
            // @see https://vuejsdevelopers.com/2017/03/05/vue-js-reactivity/
            const treeItem = clone(source);
            this.sourceTree.push(treeItem);
            return source.uid;
        });
        TreeWalker(this.$store.state.sources, (node: any, parents: any[]) => {
            if (uids.indexOf(node.uid) > -1) {
                this.sourceParents[node.uid] = parents;
            }
        });
        this.sourceTree.push(this.createNavigatorSources());
        // wait some time to load the accordion entries
        setTimeout(() => {
            this.initSourcesAccordion();
        }, 250);
    }
    // get the parent site from the path
    getParentSite() {
        const path = this.$store.getters.path;
        for (let i = path.length - 1; i >= 0; i--) {
            if (path[i].prop == 'sites') {
                return path[i];
            }
        }
        return null;
    }
    initSourcesAccordion() {
        if (!!this.site && !!this.site.sources && !!this.$refs.sourcesAcc) {
            this.site.sources.forEach((source: NavigatorSourceData, index: number) => {
                (<Vue>this.$refs.sourcesAcc).$emit('register', source.uid, index == 0);
            });
        }
    }
    getSourceParent(uid: string) {
        const parents = this.sourceParents[uid] || [];
        return parents[0] ? parents[0].name : '';
    }

    toggleNode(uid: string) {
        TreeWalker(this.sourceTree, (node: any) => {
            if (node.uid == uid) {
                node.open = !node.open;
                this.treeHelper.openState[uid] = node.open;
            }
        });
    }
    selectNode(uid: string) {
        console.log(uid);
    }
    createNavigatorSources() {
        return {
            children: [
                {
                    attributes: {},
                    children: [] as any[],
                    code: this.normalize.toKebab(this.$i18n.t('link').toString()),
                    icon: 'ri-link',
                    identifier: '',
                    name: this.$i18n.t('link'),
                    open: false,
                    selected: false,
                    type: 'link',
                    target: '_self',
                    uid: uuidv4(),
                },
            ],
            code: this.normalize.toKebab(this.$i18n.t('navigator-source').toString()),
            icon: 'ri-compass-line',
            name: this.$i18n.t('navigator-source'),
            open: true,
            selected: false,
            type: 'navigator',
            uid: uuidv4(),
            url: location.origin,
        };
    }
    async deleteNavigation() {
        const result = await this.api.delete(`/navigation/${this.$store.getters.uid}`);
        this.showDelete = false;
        const msg = new MessageModel();
        if (result && result.status >= 200 && result.status < 300) {
            msg.setType(MessageTypesEnum.Success);
            msg.setContent(this.$i18n.t('success-delete'));
            // get the parent from the path
            const parent = this.getParentSite();
            // remove the navigation from the parent
            Walker(this.$store.state.data, (node: any) => {
                if (node.uid == parent.uid) {
                    node.navigations = node.navigations.filter((m: NavigatorNavigationData) => {
                        return m.uid != this.$store.getters.uid;
                    });
                }
            });
            // select the parent
            $events.emitNodeSelect(parent.uid);
            // this.$store.state.node.navigations = this.$store.state.node.navigations.filter()
            $events.emitDataAsk();
        } else {
            msg.setType(MessageTypesEnum.Error);
            msg.setContent(this.$i18n.t('error-delete'));
        }
        $events.emitMessage(msg);
    }
    changeName(value: string) {
        if (this.$store.getters.nodeCode == this.normalize.toKebab(this.$store.getters.nodeName) || !this.$store.getters.nodeCode) {
            $events.emitNodeUniqPropertyChange(this.$store.getters.uniq, 'code', this.normalize.toKebab(value));
        }
        $events.emitNodeUniqPropertyChange(this.$store.getters.uniq, 'name', value);
        this.deferredSave();
    }
    changeCode(value: string) {
        $events.emitNodeUniqPropertyChange(this.$store.getters.uniq, 'code', value);
        this.deferredSave();
    }
    changeSelectedEntryName(value: string) {
        if (this.selectedEntry.code == this.normalize.toKebab(this.selectedEntry.name) || !this.selectedEntry.code) {
            $events.emitNodeUniqPropertyChange(this.selectedEntry.uniq, 'code', this.normalize.toKebab(value));
        }
        $events.emitNodeUniqPropertyChange(this.selectedEntry.uniq, 'name', value);
    }
    changeSelectedEntryCode(value: string) {
        $events.emitNodeUniqPropertyChange(this.selectedEntry.uniq, 'code', value);
    }
    dropSource(target: Element, source: Element, context: string, data: any) {
        console.log(target, source, context, data);
        data.uniq = uuidv4();
        this.$store.state.node.navigation.push(data);
        this.deferredSave();
    }
    deferredSave() {
        if (!!this.deferrer) {
            clearTimeout(this.deferrer);
        }

        const state = this.$store.getters.node;
        this.deferrer = setTimeout(async () => {
            const navigation = {
                name: state.name,
                code: state.code,
                navigation: state.navigation,
            };
            const response = await this.api.put(`/navigation/${this.$store.getters.uid}`, navigation);
            const msg = new MessageModel();
            if (!!response && response.status == 200) {
                msg.setType(MessageTypesEnum.Success);
                msg.setContent(this.$i18n.t('success-save'));
            } else {
                msg.setType(MessageTypesEnum.Error);
                msg.setContent(this.$i18n.t('error-save'));
            }
            $events.emitMessage(msg);
        }, __SAVE_DELAY__);
    }

    entryMove(e: MouseEvent) {
        if (!!this.isDragging) {
            //console.log('move', e);
            const settings = clone(this.dragMasterSettings);
            settings['--x'] = `${e.x}px`;
            settings['--y'] = `${e.y}px`;
            // console.log(settings);
            this.dragMasterSettings = settings;
            let update = false;
            // move down
            if (e.y > this.dragStartCoords.y + this.dragStartCoords.step) {
                update = this.entryMoveAction((entryParentLevel: any[], grandParent: any) => {
                    return this.entrySwapAction(entryParentLevel, grandParent, true);
                });
            }
            // move up
            if (e.y < this.dragStartCoords.y - this.dragStartCoords.step) {
                update = this.entryMoveAction((entryParentLevel: any[], grandParent: any) => {
                    return this.entrySwapAction(entryParentLevel, grandParent, false);
                });
            }
            // set as child
            if (e.x > this.dragStartCoords.x + this.dragStartCoords.step) {
                update = this.entryMoveAction((entryParentLevel: any[], grandParent: any) => {
                    return this.entryAsChild(entryParentLevel, grandParent);
                });
            }
            // set as parent
            if (e.x < this.dragStartCoords.x - this.dragStartCoords.step) {
                update = this.entryAsParent();
            }
            if (update) {
                this.dragMadeChanges = true;
                const event: any = { x: e.x, y: e.y, target: null as any };
                event.target = this.dragEntryTarget;
                this.entryDrag(event, this.dragEntry, this.dragEntryParents, false);
            }
        }
    }
    entryMoveAction(action: (entryParentLevel: any[], entryGrandParent: any, ...params: any[]) => void, ...params: any[]) {
        let nav = clone(this.$store.state.node.navigation);
        let changed = false;

        let found = false;
        if (!!this.dragEntryParents && Array.isArray(this.dragEntryParents)) {
            TreeWalker(nav, (node: any, parents: any[]) => {
                if (!node || !node.uniq) {
                    return;
                }
                if (node.uniq == this.dragEntryParents[this.dragEntryParents.length - 1]) {
                    const parent = !!parents && parents.length > 0 ? parents[parents.length - 1] : null;
                    node.children = action(node.children, parent, ...params);
                    found = true;
                    changed = true;
                }
            });
        }
        // when swap is used in root then walker not working
        if (!found) {
            nav = action(nav, null, ...params);
            changed = true;
        }

        this.$store.state.node.navigation = nav;
        return changed;
    }
    entryAsChild(nav: any[], grandParent: any) {
        const index = nav.findIndex((n: any) => {
            return n.uniq == this.dragEntry.uniq;
        });
        if (index == -1) {
            this.notPossible();
            return nav;
        }

        let insertInto = index - 1;
        if (insertInto < 0) {
            insertInto = 0;
        }
        // check if can be set as children
        if (!nav[insertInto] || insertInto == index) {
            this.notPossible();
            return nav;
        }
        // remove the entry
        nav.splice(index, 1);
        // insert the ne child
        if (!nav[insertInto].children) {
            nav[insertInto].children = [];
        }
        nav[insertInto].children.push(clone(this.dragEntry));
        return nav;
    }
    entryAsParent() {
        let nav = clone(this.$store.state.node.navigation);
        if (this.dragEntryParents.length < 1) {
            return false;
        }
        const parentUniq = this.dragEntryParents[this.dragEntryParents.length - 2];
        const currentParentUniq = this.dragEntryParents[this.dragEntryParents.length - 1];
        let cancel = false;
        // insert into root
        if (!parentUniq) {
            // check if already in root
            if (
                nav.findIndex((n: any) => {
                    return n.uniq == this.dragEntry.uniq;
                }) > -1
            ) {
                return false;
            }
            // add to root
            nav.push(clone(this.dragEntry));
        } else {
            // find new parent and insert into
            TreeWalker(nav, (node: any) => {
                if (node.uniq == parentUniq) {
                    if (!node.children) {
                        node.children = [];
                    }
                    // check if already in new parent
                    if (
                        node.children.findIndex((n: any) => {
                            return n.uniq == this.dragEntry.uniq;
                        }) > -1
                    ) {
                        cancel = true;
                        return;
                    }
                    node.children.push(clone(this.dragEntry));
                }
            });
        }
        if (!!cancel) {
            this.notPossible();
            return false;
        }
        // remove from the current parent
        TreeWalker(nav, (node: any) => {
            if (node.uniq == currentParentUniq) {
                node.children = node.children.filter((n: any) => {
                    return n.uniq != this.dragEntry.uniq;
                });
            }
        });

        this.$store.state.node.navigation = nav;
        return true;
    }
    entrySwapAction(nav: any[], grandParent: any, next: boolean) {
        const index = nav.findIndex((n: any) => {
            return n.uniq == this.dragEntry.uniq;
        });
        if (index == -1) {
            console.error('not found', index, this.dragEntry.uniq, nav);
            return nav;
        }
        // move down
        if (!!next && index + 1 < nav.length) {
            nav[index] = nav[index + 1];
            nav[index + 1] = clone(this.dragEntry);
        }
        // move up
        if (!next && index > 0) {
            nav[index] = nav[index - 1];
            nav[index - 1] = clone(this.dragEntry);
        }
        return nav;
    }
    releaseItem(e: Event) {
        this.isDragging = false;
        if (this.dragMadeChanges) {
            this.deferredSave();
            this.dragMadeChanges = false;
        }
        // console.log('release', e)
    }
    dragoverItem(e: Event) {
        // console.log('over', e.target)
    }
    dragleaveItem(e: Event) {
        // console.log('leave', e.target)
    }

    entryDrag(e: MouseEvent, entry: any, parents: string[], updateBounds: boolean = true) {
        if (!!entry) {
            this.dragUniq = entry.uniq;
            const target = <HTMLElement>e.target;
            const master = target.closest('.m-drag-clone__master');
            const masterStyles = getComputedStyle(master);
            const step = Math.min(parseInt(masterStyles.height, 10) / 2, parseInt(masterStyles.width, 10) / 2);
            this.dragStartCoords = {
                x: e.x,
                y: e.y,
                step,
            };
            if (!updateBounds) {
                return;
            }

            const boundsMaster = master.getBoundingClientRect();
            this.dragUid = entry.uid;
            this.dragEntry = entry;
            this.dragEntryParents = parents;
            this.dragEntryTarget = e.target;
            this.isDragging = true;
            this.dragMasterSettings = {
                width: masterStyles.width,
                height: masterStyles.height,
                '--x': `${e.x}px`,
                '--y': `${e.y}px`,
                'margin-left': `${-1 * e.x + boundsMaster.x}px`,
                'margin-top': `${-1 * e.y + boundsMaster.y}px`,
            };
        }
    }
    // save entries
    entryUpdate(entry: any, parents: string[] = []) {
        this.$store.state.node.navigation = entry.children;
        this.deferredSave();
    }
    entryEdit(entry: any, parents: string[]) {
        const data = clone(entry);
        this.selectedEntry = data;
        this.selectedEntryParents = parents;
        this.showEntryEdit = true;
    }
    entryEditAction() {
        this.deferredSave();
        this.showEntryEdit = false;
    }
    // delete of the entries
    showEntryDeleteQuestion(entry: any, parents: string[]) {
        this.selectedEntry = entry;
        this.selectedEntryParents = parents;
        this.showEntryDelete = true;
    }
    entryDeleteAllowed() {
        (<Vue>this.$refs.listEntryTree).$emit('delete-allowed', this.selectedEntry, this.selectedEntryParents);
        this.showEntryDelete = false;
    }

    notPossible() {
        if (!!this.notPossibleTimer) {
            clearTimeout(this.notPossibleTimer);
        }
        this.notPossibleTimer = setTimeout(() => {
            $events.emitMessage(new MessageModel(MessageTypesEnum.Warning, this.$i18n.t('not-possible')));
        }, 500);
    }

    openEntrySource() {
        this.showEntrySource = true;
    }
    get getEntrySourceCode() {
        return JSON.stringify(this.$store.state.node.navigation, null, 4)
            .replace(/\"(.*?)\"/g, '<span class="m-code__string">"$1"</span>')
            .replace(/([\[\{\]\}])/g, '<span class="m-code__group">$1</span>');
    }
}
