import axios, { AxiosInstance } from 'axios';
import pkg from './../../package.json';
import { $events } from './../lib/events';
import { MessageModel, MessageTypesEnum } from '../components/messages';

export class API {
    ax: AxiosInstance;
    auth: any = null;
    constructor(private scope: string = '') {
        this.ax = axios.create(this.getConfig());
        $events.onAuthGet((auth: any) => {
            this.auth = auth;
        });
        $events.emitAuthAsk();
    }
    getConfig(token: string | null = null) {
        let config = {
            baseURL: `${location.origin}/`,
            headers: {
                'cannonball-navigator': pkg.version || '0.0.0',
            } as any,
        };
        if (!!this.auth && !!this.auth.access_token) {
            config['headers']['Authorization'] = `Bearer ${this.auth.access_token}`;
            // } else {
            //     console.warn('no auth', this.scope)
        }
        return config;
    }
    async post(url: string, data: any = null, token: string = null) {
        const config = this.getConfig(token);
        try {
            const response = await axios.post(url, data, config);
            return response;
        } catch (e) {
            return this.handleError(e);
        }
    }
    async put(url: string, data: any = null, token: string = null) {
        const config = this.getConfig(token);
        try {
            const response = await axios.put(url, data, config);
            return response;
        } catch (e) {
            return this.handleError(e);
        }
    }
    async delete(url: string, token: string = null) {
        const config = this.getConfig(token);
        try {
            const response = await axios.delete(url, config);
            return response;
        } catch (e) {
            return this.handleError(e);
        }
    }
    async get(url: string, token: string = null) {
        const config = this.getConfig(token);
        try {
            const response = await axios.get(url, config);
            return response;
        } catch (e) {
            return this.handleError(e);
        }
    }
    handleError(error: any): null {
        console.log(JSON.parse(JSON.stringify(error)));
        if (error) {
            if (error.request) {
                switch (error.request.status) {
                    default:
                        if (error.response && error.response.data && error.response.data.message) {
                            $events.emitMessage(new MessageModel(MessageTypesEnum.Error, JSON.stringify(error.response.data, null, 4)));
                        }
                        break;
                    case 401: // Unauthorized
                    case 403:
                        $events.emitLogout();
                        break;
                }
            }
        }
        return null;
    }
}
