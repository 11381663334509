import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './site.html';
import { $events } from '../lib/events';
import Window from '../components/window';
import Accordion from '../components/accordion';
import AccordionEntry from '../components/accordion-entry';
import Modal from '../components/modal';
import Btn from '../components/btn';
import ListEntry from '../components/list-entry';
import Textbox from '../components/form/textbox';
import Search from '../components/form/search';
import Tree from '../components/tree';
import { Dropzone } from '../components/dropzone';
import { NavigatorSource } from '../model/navigator-source';
import { TreeWalker, Walker } from '../lib/walker';
import { API } from './../lib/api';
import { MessageModel, MessageTypesEnum } from './../components/messages';
import { Normalize } from '../lib/normalize';
import { TreeHelper } from '../helper/tree';
import { NavigatorUniqueData, NavigatorSourceData, NavigatorNavigationData, NavigatorSiteData } from '../model/navigator-data';

@WithRender
@Component({
    components: {
        Window,
        Accordion,
        AccordionEntry,
        Modal,
        Btn,
        Textbox,
        Search,
        ListEntry,
        Tree,
        Dropzone,
    },
})
export class SiteView extends Vue {
    showSiteConfig: boolean = false;
    showDelete: boolean = false;
    showNavigationAdd: boolean = false;
    searchText: string = '';
    sourceTree: any = [];
    // sources: any[] = [];
    allowedTypesForTree = ['magento', 'website', 'store', 'store-view', 'typo3', 'language', 'service', 'systemx'];
    inheritance: any = {};
    newNavigation = {
        name: '',
        code: '',
        site: '',
    };
    deferrer: any = null;
    api = new API('site');
    normalize = new Normalize();
    treeHelper = new TreeHelper();
    navigations: NavigatorNavigationData[] = [];
    mounted() {
        this.inheritance = {};

        this.sourceTree = [];
        this.treeHelper.getSourceTree(this.$store.getters.sources, this.allowedTypesForTree).forEach((source: any) => {
            source.open = true;
            // needs to be cloned, because vue adds getter and setter for every property
            // and when the getter/setter are existing already and
            // new properties has been added
            // no new getter and setter will be added for the new property
            // @see https://vuejsdevelopers.com/2017/03/05/vue-js-reactivity/
            const treeItem = JSON.parse(JSON.stringify(source));
            this.sourceTree.push(treeItem);
        });

        // wait some time to load the accordion entries
        setTimeout(() => {
            this.initSourcesAccordion();
            // build inheritance
            this.generateInheritance();
        }, 250);

        this.navigations = this.$store.getters.siteNavigations;
    }
    created() {
        // BUGGY because gets created multiple times
        $events.onNodeSelect(() => {
            this.navigations = this.$store.getters.siteNavigations;
        });
    }
    initAccordion() {
        (<Vue>this.$refs.acc).$emit('register', 'site', true);
        (<Vue>this.$refs.acc).$emit('register', 'sources', true);
        (<Vue>this.$refs.acc).$emit('register', 'navigations', true);
    }
    initSourcesAccordion() {
        this.$store.getters.sources.forEach((source: NavigatorSource, index: number) => {
            (<Vue>this.$refs.sourcesAcc).$emit('register', source.uid, index == 0);
        });
    }
    changeName(value: string) {
        if (this.$store.getters.nodeCode == this.normalize.toKebab(this.$store.getters.nodeName) || !this.$store.getters.nodeCode) {
            $events.emitNodePropertyChange(this.$store.getters.uid, 'code', this.normalize.toKebab(value));
        }
        $events.emitNodePropertyChange(this.$store.getters.uid, 'name', value);
        this.deferredSave();
    }
    changeCode(value: string) {
        $events.emitNodePropertyChange(this.$store.getters.uid, 'code', value);
        this.deferredSave();
    }
    changeLanguage(value: string) {
        $events.emitNodePropertyChange(this.$store.getters.uid, 'language', value);
        this.deferredSave();
    }
    deferredSave() {
        if (!!this.deferrer) {
            clearTimeout(this.deferrer);
        }
        const state = JSON.parse(JSON.stringify(this.$store.getters.node));
        this.deferrer = setTimeout(async () => {
            const site = {
                name: state.name,
                code: state.code,
                language: state.language,
                domain: state.domain,
            };
            const response = await this.api.put(`/site/${state.uid}`, site);
            this.showMessage(response, 'save');
        }, __SAVE_DELAY__);
    }
    changeSearch(value: string) {
        console.log(value);
    }
    changeNewNavigationName(value: string) {
        if (this.newNavigation.code == this.normalize.toKebab(this.newNavigation.name) || !this.newNavigation.code) {
            this.newNavigation.code = this.normalize.toKebab(value);
        }
        this.newNavigation.name = value;
    }
    changeNewNavigationCode(value: string) {
        this.newNavigation.code = this.normalize.toKebab(value);
    }
    openNavigation(uid: string) {
        $events.emitNodeSelect(uid);
    }
    toggleNode(uid: string) {
        TreeWalker(this.sourceTree, (node: any) => {
            if (node.uid == uid) {
                node.open = !node.open;
                this.treeHelper.openState[uid] = node.open;
            }
        });
    }
    selectNode(uid: string) {
        console.log(uid);
    }
    async dropSource(target: Element, source: Element, context: string, data: any) {
        const uids = this.$store.state.node.sources.map((s: any) => s.uid);
        if (uids.indexOf(data.uid) == -1) {
            this.$store.state.node.sources.push(data);
            this.generateInheritance();
            const sources = this.$store.state.node.sources.map((source: NavigatorSourceData) => {
                return source.uid;
            });
            const response = await this.api.put(`/site/${this.$store.getters.uid}`, { sources });
            this.showMessage(response, 'save');
        }
    }
    generateInheritance() {
        const inheritanceRaw: any = {};
        const inherit: any = {};
        TreeWalker(this.$store.state.sources, (node: NavigatorSource) => {
            if (node.children && node.children.length > 0) {
                node.children.forEach((child: NavigatorSource) => {
                    inheritanceRaw[child.uid] = {
                        uid: node.uid,
                        name: node.name,
                    };
                });
            }
        });
        this.$store.state.node.sources.forEach((source: NavigatorSource) => {
            const inheritance = [];
            let uid = source.uid;
            do {
                if (inheritanceRaw[uid]) {
                    inheritance.unshift(inheritanceRaw[uid].name);
                    uid = inheritanceRaw[uid].uid;
                    continue;
                }
                uid = null;
            } while (uid);
            inherit[source.uid] = inheritance;
        });
        this.inheritance = inherit;
    }
    async removeSource(source: NavigatorSource) {
        this.$store.state.node.sources = this.$store.state.node.sources.filter((s: NavigatorSource) => {
            return s.uid != source.uid;
        });
        const sources = this.$store.state.node.sources.map((source: NavigatorSourceData) => {
            return source.uid;
        });
        const response = await this.api.put(`/site/${this.$store.getters.uid}`, { sources });
        this.showMessage(response, 'delete');
    }
    async addNavigation() {
        this.newNavigation.site = this.$store.getters.uid;
        const response = await this.api.post('/navigation', this.newNavigation);
        // add to store
        this.$store.state.node.navigations.push(response.data);
        // bugfix, set for current instance
        this.navigations = this.$store.getters.siteNavigations;
        if (response && response.data) {
            $events.emitDataAsk();
        }
        this.showMessage(response, 'save');
        this.closeAddNavigation();
    }
    closeAddNavigation() {
        this.showNavigationAdd = false;
        this.newNavigation = {
            name: '',
            code: '',
            site: '',
        };
    }
    showMessage(response: any, context: string = 'save') {
        const msg = new MessageModel();
        if (response && response.status >= 200 && response.status < 300) {
            msg.setType(MessageTypesEnum.Success);
            msg.setContent(this.$i18n.t(`success-${context}`));
        } else {
            msg.setType(MessageTypesEnum.Error);
            msg.setContent(this.$i18n.t(`error-${context}`));
        }
        $events.emitMessage(msg);
    }
    getParentDomain() {
        const path = this.$store.getters.path;
        console.log(path);
        for (let i = path.length - 1; i >= 0; i--) {
            if (path[i].prop == '') {
                return path[i];
            }
        }
        return null;
    }
    async deleteSite() {
        const response = await this.api.delete(`/site/${this.$store.getters.uid}`);
        this.showDelete = false;
        if (response && response.status >= 200 && response.status < 300) {
            // get the parent from the path
            const parent = this.getParentDomain();
            // remove the navigation from the parent
            Walker(this.$store.state.data, (node: any) => {
                if (node.uid == parent.uid) {
                    node.sites = node.sites.filter((d: NavigatorUniqueData) => {
                        return d.uid != this.$store.getters.uid;
                    });
                }
            });
            this.$store.state.sites = this.$store.state.sites.filter((site: NavigatorSiteData) => { return site.uid != this.$store.getters.uid})
            // select the parent
            $events.emitNodeSelect(parent.uid);
            // this.$store.state.node.navigations = this.$store.state.node.navigations.filter()
            $events.emitDataAsk();
        }
        this.showMessage(response, 'delete');
    }
}
