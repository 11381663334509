import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './messages.html';
import { $events } from '../lib/events';
import VueI18n from 'vue-i18n';

@WithRender
@Component
export class Messages extends Vue {
    list: MessageStateModel[] = [];
    index = 0;
    mounted() {
        $events.onMessage((message: MessageModel) => {
            const idx = this.index;
            message.id = idx;
            const state = new MessageStateModel(message);

            this.list.push(state);
            this.index++;
            if (state.getTimeout()) {
                if (state.getTimeout() > 1500) {
                    setTimeout(
                        (index: number) => {
                            this.list.forEach((msg) => {
                                if (msg.id == index) {
                                    msg.hide = true;
                                }
                            });
                        },
                        state.getTimeout() - 1000,
                        idx
                    );
                }
                setTimeout(() => {
                    this.list.shift();
                }, state.getTimeout());
            }
        });
    }
    cssClass(msg: MessageStateModel) {
        let type = 'm-message--info';
        if (msg && msg.getType()) {
            switch (msg.getType()) {
                case MessageTypesEnum.Success:
                    type = 'm-message--success';
                    break;
                case MessageTypesEnum.Warning:
                    type = 'm-message--warning';
                    break;
                case MessageTypesEnum.Error:
                    type = 'm-message--error';
                    break;
            }
        }
        const classes = {
            bounceInDown: !msg.hide,
            bounceOutUp: !!msg.hide,
        };
        (<any>classes)[type] = true;
        return classes;
    }
    close(msg: MessageModel) {
        if (msg && !isNaN(msg.id)) {
            this.list = this.list.filter((m) => m.id != msg.id);
        }
    }

    get hasMessages() {
        return this.list.length > 0;
    }
    get getMessages() {
        return this.list;
    }
}
export class MessageModel {
    id: number;
    constructor(private type: MessageTypesEnum = MessageTypesEnum.Info, private content: string | any = '', private timeout: number = 5000) {}
    setId(id: number) {
        this.id = id;
    }
    setType(type: MessageTypesEnum) {
        this.type = type;
    }
    setContent(content: string | VueI18n.TranslateResult) {
        if (typeof content != 'string') {
            content = content.toString();
        }
        this.content = content;
    }
    setTimeout(timeout: number | null = null) {
        this.timeout = timeout;
    }
    getType() {
        return this.type;
    }
    getContent() {
        return this.content;
    }
    getTimeout() {
        return this.timeout;
    }
}
class MessageStateModel extends MessageModel {
    constructor(msg: MessageModel | null = null) {
        super();
        if (msg) {
            this.setId(msg.id);
            this.setContent(msg.getContent());
            this.setTimeout(msg.getTimeout());
            this.setType(msg.getType());
        }
    }
    hide: boolean = false;
}
export enum MessageTypesEnum {
    Info,
    Success,
    Warning,
    Error,
}
