import { Component, Vue } from 'vue-property-decorator';
import WithRender from './site-tree.html';
import { $events } from './../lib/events';
import Tree from './tree';
import { NavigatorSiteData, NavigatorNavigationData, NavigatorDomainData } from '../model/navigator-data';
import { TreeWalker } from './../lib/walker';

@WithRender
@Component({ components: { Tree } })
export default class SiteTree extends Vue {
    data: any = null;
    treeData: any = null;
    domainSelected: boolean = false;
    previousUid: string | null = null;
    openState: any = {};

    mounted() {
        $events.onDataGet((data: any) => this.getData(data));
        $events.emitDataAsk();
        // select node
        $events.onNodeSelect((uid: string) => {
            // allow deselecting the domain
            this.domainSelected = this.data.uid == uid;
            TreeWalker(this.$store.state.treeData, (node: any, parents: any[]) => {
                if (node.uid == this.previousUid) {
                    node.selected = false;
                }
                if (node.uid == uid) {
                    node.selected = true;
                    // open the parents of the selected node
                    parents.forEach((parent) => {
                        parent.open = true;
                    });
                }
            });
            this.previousUid = uid;
        });
        $events.onNodePropertyChangeDone((uid: string, prop: string, value: any) => {
            this.createTreeData();
        });
    }
    getData(data: any) {
        this.data = data;
        this.createTreeData();
        //this.treeData = this.getTreeData();
    }
    selectNode(uid: string) {
        $events.emitNodeSelect(uid);
    }
    toggleNode(uid: string) {
        TreeWalker(this.$store.state.treeData, (node: any) => {
            if (node.uid == uid) {
                node.open = !node.open;
                this.openState[uid] = node.open;
            }
        });
    }

    createTreeData(): void {
        if (!this.$store.getters.hasData) {
            return;
        }
        const treeData: any[] = [];
        if (!this.data) {
            return;
        }
        this.data.forEach((domain: NavigatorDomainData) => {
            const domainChildren: any[] = [];
            if (!!domain.sites) {
                domain.sites.forEach((site: NavigatorSiteData) => {
                    const siteChildren: any[] = [];
                    if (!!site.navigations) {
                        site.navigations.forEach((navigation: NavigatorNavigationData) => {
                            siteChildren.push({
                                name: navigation.name,
                                uid: navigation.uid,
                                icon: 'ri-compass-line',
                                selected: navigation.uid == this.$store.getters.uid,
                                open: this.openState[navigation.uid] || false,
                                code: navigation.code,
                                children: null,
                            });
                        });
                    }
                    domainChildren.push({
                        name: site.name,
                        uid: site.uid,
                        icon: 'ri-book-open-line',
                        selected: site.uid == this.$store.getters.uid,
                        open: this.openState[site.uid] || false,
                        code: site.code,
                        children: siteChildren.length ? siteChildren : null,
                    });
                });
            }
            treeData.push({
                name: domain.name,
                uid: domain.uid,
                icon: 'ri-book-mark-line',
                children: domainChildren.length ? domainChildren : null,
                selected: domain.uid == this.$store.getters.uid || this.$store.getters.uid == null,
                open: this.openState[domain.uid] || false,
                code: null,
            });
        });
        // update store
        this.$store.state.treeData = treeData;
    }
    selectDomain() {
        const uid = this.data.uid;
        this.selectNode(uid);
    }
}
