import { NavigatorSource } from '../model/navigator-source';
import { v4 as uuidv4 } from 'uuid';

export class TreeHelper {
    openState: any = {};
    getSourceIcon(type: string) {
        switch (type) {
            case 'magento':
            case 'magento2':
            case 'typo3':
                return `m-system-icon m-system-icon--${type}`;
            case 'website':
                return 'ri-window-line';
            case 'store':
                return 'ri-store-2-line';
            case 'store_view':
                return 'ri-pages-line';
            case 'category':
                return 'ri-folder-3-line';
            case 'cms_page':
                return 'ri-file-2-line';
            case 'brands':
                return 'ri-star-line';
        }
        return 'ri-question-line';
    }
    getSourceTree(sources: NavigatorSource[], allowedTypes: string[] | null = null): any[] {
        return sources.filter((item: NavigatorSource) => {
            if (item.children?.length) {
                item.children = this.getSourceTree(item.children, allowedTypes);
            }
            (<any>item).open = this.openState[item.uid] || false;
            const icon = this.getSourceIcon(item.type);
            (<any>item).icon = icon;
            (<any>item).selected = false;
            (<any>item).uniq = uuidv4();
            //(<any>item).id = '';
            if (!!allowedTypes) {
                const match = allowedTypes.indexOf(<any>item.type) > -1;
                return match != null;
            }
            return true;
        });
    }
}
