import { Component, Vue } from 'vue-property-decorator';
import { MessageModel } from '../components/messages';

@Component
class EventEmitter extends Vue {
    onNodeSelect(callback: (uid: string | null) => void): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.NodeSelect, callback);
    }
    emitNodeSelect(uid: string | null) {
        this.$emit(EventKeys.NodeSelect, uid);
    }
    onNodeUniqPropertyChange(callback: (uid:string, key: string, value: any) => void): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.NodeUniqPropertyChange, callback);
    }
    emitNodeUniqPropertyChange(uid:string, key: string, value: any) {
        this.$emit(EventKeys.NodeUniqPropertyChange, uid, key, value);
    }
    onNodePropertyChange(callback: (uid:string, key: string, value: any) => void): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.NodePropertyChange, callback);
    }
    emitNodePropertyChange(uid:string, key: string, value: any) {
        this.$emit(EventKeys.NodePropertyChange, uid, key, value);
    }
    onNodePropertyChangeDone(callback: (uid:string, key: string, value: any) => void): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.NodePropertyChangeDone, callback);
    }
    emitNodePropertyChangeDone(uid:string, key: string, value: any) {
        this.$emit(EventKeys.NodePropertyChangeDone, uid, key, value);
    }
    onMessage(callback: (message: MessageModel) => void): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.Message, callback);
    }
    emitMessage(messageModel: MessageModel) {
        this.$emit(EventKeys.Message, messageModel);
    }
    onViewShow(callback: (key: string) => void): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.ViewShow, callback);
    }
    emitViewShow(key: string) {
        console.log('show view', key)
        this.$emit(EventKeys.ViewShow, key);
    }
    onLocaleSwitch(callback: (locale: string) => void): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.LocaleSwitch, callback);
    }
    emitLocaleSwitch(locale: string) {
        this.$emit(EventKeys.LocaleSwitch, locale);
    }
    onAppReady(callback: Function): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.AppReady, callback);
    }
    emitAppReady() {
        this.$emit(EventKeys.AppReady);
    }
    onAppBroken(callback: Function): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.AppBroken, callback);
    }
    emitAppBroken() {
        this.$emit(EventKeys.AppBroken);
    }
    onDragStart(callback: (source: Element, context: string, data: any) => void): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.DragStart, callback);
    }
    emitDragStart(source: Element, context: string, data: any) {
        this.$emit(EventKeys.DragStart, source, context, data);
    }
    onDragEnd(callback: (target: Element, source: Element, context: string, data: any) => void): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.DragEnd, callback);
    }
    emitDragEnd(target: Element, source: Element, context: string, data: any) {
        this.$emit(EventKeys.DragEnd, target, source, context, data);
    }
    onLoginForce(callback: (data: any) => void): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.LoginForce, callback);
    }
    emitForceLogin(data: any | null = null) {
        this.$emit(EventKeys.LoginForce, data);
    }
    onLogin(callback: Function): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.Login, callback);
    }
    emitLogin() {
        this.$emit(EventKeys.Login);
    }
    onLogout(callback: Function): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.Logout, callback);
    }
    emitLogout() {
        this.$emit(EventKeys.Logout);
    }
    onAuthGet(callback: (data: any | null) => void): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.AuthGet, callback);
    }
    emitAuthGet(data: any | null = null) {
        this.$emit(EventKeys.AuthGet, data);
    }
    onAuthAsk(callback: Function): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.AuthAsk, callback);
    }
    emitAuthAsk(){
        this.$emit(EventKeys.AuthAsk);
    }
    onAuthInvalidate(callback: Function): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.AuthInvalidate, callback);
    }
    emitAuthInvalidate() {
        this.$emit(EventKeys.AuthInvalidate);
    }
    onDataAsk(callback: Function): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.DataAsk, callback);
    }
    emitDataAsk() {
        this.$emit(EventKeys.DataAsk);
    }
    onDataGet(callback: (data: any)=>void): void {
        if (!callback || typeof callback != 'function') {
            return;
        }
        this.$on(EventKeys.DataGet, callback);
    }
    emitDataGet(data:any) {
        this.$emit(EventKeys.DataGet, data);
    }
}

export const $events = new EventEmitter({
    el: '[id="$events"]',
});

let iterator = 0;
function getKey(): string {
    iterator++;
    return `e${iterator}`;
}

export const EventKeys = {
    LocaleSwitch: getKey(),
    Message: getKey(),
    AppReady: getKey(),
    AppBroken: getKey(),
    ViewShow: getKey(),
    NodeSelect: getKey(),
    NodePropertyChange: getKey(),
    NodeUniqPropertyChange: getKey(),
    NodePropertyChangeDone: getKey(),
    DragStart: getKey(),
    DragEnd: getKey(),
    AuthInvalidate: getKey(),
    AuthGet: getKey(),
    AuthAsk: getKey(),
    LoginForce: getKey(),
    Login: getKey(),
    Logout: getKey(),
    DataGet: getKey(),
    DataAsk: getKey()
};