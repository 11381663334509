<div class="p-domain u-fullsize">
    <accordion @mounted="initAccordion" ref="accordion">
        <accordion-entry id="domain" name="domain" icon="ri-book-mark-fill">
            <template v-slot:header>{{ $t('domain') }}</template>
            <template v-slot:content>
                <div class="u-padding u-content">
                    <Textbox :value="$store.getters.nodeName" @input="changeName">{{ $t('name') }}</Textbox>
                    <Textbox :value="$store.getters.nodeUrl" @input="changeUrl">{{ $t('url') }}</Textbox>
                </div>
            </template>
        </accordion-entry>
        <accordion-entry id="sites" name="sites" icon="ri-book-open-fill">
            <template v-slot:header>{{ $t('sites') }}</template>
            <template v-slot:content>
                <div class="u-padding u-content">
                    <list-entry v-for="site in $store.getters.sites" icon="ri-book-open-line" :show-button="true" :clickable="true" @click="openSite(site.uid)">
                        <template v-slot:content>{{ site.name }} <span class="u-light">{{ site.code }}</span></template>
                        <template v-slot:button>
                            <!-- <Btn @click="showSiteAdd=true" icon="ri-pencil-line" :only-icon="true"></Btn> -->
                            <i class="u-text--size-x1_5 ri-pencil-line"></i>
                        </template>
                    </list-entry>
                    
                    <div class="u-padding-top">
                        <Btn @click="showAddSite=true" icon="ri-add-line" :primary="true">{{ $t('add-site') }}</Btn>
                    </div>
                </div>
            </template>
            
        </accordion-entry>
    </accordion>

    <Modal :show="showAddSite" @close="closeAddSite" :show-footer="true">
        <template v-slot:header>{{ $t('add-site') }}</template>
        <template v-slot:content>
            <Textbox :value="newSite.name" @input="changeNewSiteName" @on-enter="addSite" :focus="showAddSite">{{ $t('name') }}</Textbox>
            <Textbox :value="normalize.toKebab(newSite.code)" @input="changeNewSiteCode" icon="ri-magic-line" @on-enter="addSite">{{ $t('code') }}</Textbox>
            <Textbox v-model="newSite.language" @on-enter="addSite">{{ $t('language') }}</Textbox>
        </template>
        <template v-slot:footer>
            <Btn @click="closeAddSite">{{ $t('cancel') }}</Btn>
            <Btn @click="addSite" :primary="true">{{ $t('add') }}</Btn>
        </template>
    </Modal>
</div>
