import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuex from 'vuex';
import AppMain from './app/app';
import { $events } from './lib/events';
import store from './lib/store';
import { API } from './lib/api';
import { NavigatorSource } from './model/navigator-source';
import LocalEn from './locales/en.json';
import LocalDe from './locales/de.json';
// import loadedData from './dummy/data';
// import loadedSources from './dummy/sources';

// i18n
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: LocalEn,
        de: LocalDe,
    },
});
Vue.config.productionTip = false;
export const pubsub = new Vue();

Vue.use(Vuex);

const App: any = new Vue({
    el: '#app',
    store,
    data: {
        isLoading: true,
        isBroken: false,
        data: null,
        loaded: {
            sites: false,
            sources: false,
            data: false,
            navigations: false,
        },
    },
    i18n,
    // router,
    // store: $store,
    components: {
        AppMain,
    },
    mounted: () => {
        // set locale for the user
        const storedLanguage = localStorage.getItem('locale');
        if (storedLanguage) {
            i18n.locale = storedLanguage;
        } else {
            const availableLanguages = Object.keys(i18n.messages);
            const userLanguages = navigator.languages
                .map((lang: string) => {
                    lang = lang.toLowerCase();
                    if (lang.indexOf('-')) {
                        return lang.split('-')[0];
                    }
                    return lang;
                })
                .filter((lang: string) => {
                    if (!lang) {
                        return false;
                    }
                    return availableLanguages.indexOf(lang) > -1;
                });
            if (i18n.locale != userLanguages[0]) {
                i18n.locale = userLanguages[0];
            }
        }
        $events.onLocaleSwitch((locale: string) => {
            if (Object.keys(i18n.messages).indexOf(locale) == -1) {
                return;
            }
            localStorage.setItem('locale', locale);
            i18n.locale = locale;
        });

        $events.onAppReady(() => {
            // remove loader
            App.isLoading = false;
            // navigate to the given hash
            App.navigateTo(location.hash);
        });
        $events.onAppBroken(() => {
            // remove loader
            App.isBroken = true;
        });

        // navigate to the selected element when user switch to other node
        window.addEventListener('popstate', (e: PopStateEvent) => {
            if (e && e.state && e.state.uid) {
                $events.emitNodeSelect(e.state.uid);
            }
        });
    },
    methods: {
        navigateTo(hash: string) {
            const path = hash.split('/');
            if (!path || path.length < 3 || path[0] != '#') {
                $events.emitViewShow('domain');
                return;
            }
            switch (path[1]) {
                case 'uid':
                    $events.emitNodeSelect(path[2]);
                    return;
            }
            $events.emitViewShow('domain');
        },
    },
});
