import { NavigatorPathEntry } from '../model/path';

const walker = (data: any, callback: Function, parents: any[] = [], parentProp: string = '', parentIndex: number = 0): any => {
    if (!data) {
        return null;
    }
    if (Array.isArray(data)) {
        return data
            .map((entry: any) => {
                return walker(entry, callback, parents, parentProp, parentIndex);
            })
            .filter(Boolean);
    }
    if (!data.uid) {
        return null;
    }
    const newParents = JSON.parse(JSON.stringify(parents));
    let name = '';
    switch (parentProp) {
        case '':
        case 'sites':
        case 'navigations':
            name = data.name;
            break;
    }
    const entry = new NavigatorPathEntry(name, data.uid, parentProp, parentIndex);
    newParents.push(entry);
    const aborter = callback(data, newParents);
    if (aborter === false) {
        return data;
    }
    const iterateAble = Object.keys(data).filter((prop: string) => {
        return Array.isArray(data[prop]);
    });
    if (iterateAble.length) {
        const iterateAbleWithUID = iterateAble.filter((prop: string) => {
            if (!data[prop].length) {
                return false;
            }
            return data[prop][0].uid != null;
        });
        iterateAbleWithUID.forEach((prop: string) => {
            data[prop].forEach((item: any, index: number) => {
                data[prop][index] = walker(data[prop][index], callback, newParents, prop, index);
            });
        });
    }
    return data;
};
const treeWalker = (data: any, callback: (node: any, parents: any[]) => boolean | void, parents: any[] = []) => {
    if (!Array.isArray(data)) {
        throw 'treewalker must contain an array as data';
    }
    data.forEach((node: any) => {
        const aborter = callback(node, parents);
        if (aborter === false) {
            return;
        }
        const dataParents = [...parents, node];
        if (node.children && node.children.length) {
            treeWalker(node.children, callback, dataParents);
        }
    });
};

export var Walker = walker;
export var TreeWalker = treeWalker;
