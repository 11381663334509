
import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './search.html';
import Id from './../../lib/id';

@WithRender
@Component
export default class Search extends Vue {
    @Prop({default: ''}) val: string;
    uniq: string = Id();
    open: boolean = false;
    focused: boolean = false;
    timer: number;
    preview: string = '';
    mounted() {
        
    }
    change(e:any) {
        this.update(e.target.value);
    }
    focus() {
        this.open = true;
        this.focused = true;
    }
    blur() {
        this.focused = false;
        setTimeout(()=> {
            if(!this.focused) {
                this.open = false;
            }
        }, 200);
    }
    update(value:string, instant: boolean = false) {
        // no debouncing
        if(instant) {
            this.$emit('input', value);
            this.preview = value;
            return;
        }
        // debounce to handle short times when losing focus
        if(this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(()=> {
            this.$emit('input', value);
            this.preview = value;
        }, 200);
    }
    clear() {
        this.update('', true);
    }
}