<div class="p-site u-fullsize">
    <Window reverse="true">
        <template v-slot:main>
            <accordion @mounted="initAccordion" ref="acc">
                <accordion-entry id="site" name="site" icon="ri-book-mark-fill">
                    <template v-slot:header>
                        <div>{{ $t('site') }}</div>
                    </template>
                    <template v-slot:content>
                        <div class="u-padding u-content">
                            <Textbox :value="$store.getters.nodeName" @input="changeName" :focus="true">{{ $t('name') }}</Textbox>
                            <Textbox :value="normalize.toKebab($store.getters.nodeCode)" @input="changeCode" icon="ri-magic-line">{{ $t('code') }}</Textbox>
                            <Textbox v-model="$store.getters.nodeLanguage" @input="changeLanguage">{{ $t('language') }}</Textbox>

       
                            <div class="u-padding-top">
                                <Btn @click="showDelete = true">{{ $t('delete') }}</Btn>
                            </div>
                        </div>
                    </template>
                </accordion-entry>
                <accordion-entry id="sources" name="sources" icon="ri-node-tree">
                    <template v-slot:header>
                        <div>{{ $t('sources') }}</div>
                    </template>
                    <template v-slot:content>
                        <div class="u-padding u-content" v-if="$store.getters.siteSources">
                            <Dropzone css-class="m-list-entry__container" @drop="dropSource">
                                <div v-if="$store.getters.siteSources.length == 0" class="u-align-center u-light u-padding">
                                    {{ $t('no-source') }}
                                </div>
                                <div v-for="source in $store.getters.siteSources" class="m-list-entry">
                                    <div class="m-flag">
                                        <div class="m-flag__img">
                                            <i class="u-light u-text--size-x1_5" :class="'ri-'+source.icon" :title="source.type"></i>
                                        </div>
                                        <div class="m-flag__body">
                                            <div class="m-flag m-flag--rev">
                                                <div class="m-flag__body">
                                                    <!--BUGGY because gets not updated when switched between sites-->
                                                    <div class="u-light u-text--size-x_75 u-padding-bottom--x_25" v-if="inheritance[source.uid]">
                                                        <span v-for="(inherit, index) in inheritance[source.uid]" class="u-align-middle u-padding-right--x_25">
                                                            <i class="ri-arrow-right-s-line u-light" v-if="index != 0"></i>
                                                            <span>{{inherit}}</span>
                                                        </span>
                                                    </div>
                                                    {{source.name}} <span class="u-light">{{source.code}}</span>
                                                </div>
                                                <div class="m-flag__img">
                                                    <!-- <a :href="source.url" target="_blank" v-if="source.url"><i class="ri-external-link-line u-text--size-x1_5"></i></a> -->
                                                    <Btn @click="removeSource(source)" icon="ri-delete-bin-line" only-icon="true"></Btn>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dropzone>
                        </div>
                    </template>
                </accordion-entry>
                <accordion-entry id="navigations" name="navigations" icon="ri-compass-fill">
                    <template v-slot:header>
                        <div>{{ $t('navigations') }}</div>
                    </template>
                    <template v-slot:content>
                        <div class="u-padding u-content" v-if="navigations">
                            <list-entry
                                v-for="navigation in navigations"
                                icon="ri-book-open-line"
                                :show-button="navigation.uid"
                                :clickable="true"
                                @click="openNavigation(navigation.uid)"
                                :key="navigation.uid"
                                :is-loading="!navigation.uid"
                            >
                                <template v-slot:content>{{ navigation.name }} <span class="u-light">{{ navigation.code }}</span></template>
                                <template v-slot:button>
                                    <!-- <Btn @click="showSiteAdd=true" icon="ri-pencil-line" :only-icon="true"></Btn> -->
                                    <i class="u-text--size-x1_5 ri-pencil-line"></i>
                                </template>
                            </list-entry>

                            <div class="u-padding-top">
                                <Btn @click="showNavigationAdd=true" icon="ri-add-line" :primary="true">{{ $t('add-navigation') }}</Btn>
                            </div>
                        </div>
                    </template>
                </accordion-entry>
            </accordion>
        </template>
        <template v-slot:bar>
            <div class="m-rows" v-if="$store.getters.hasSources">
                <!-- <div class="m-rows__row">
                    <Search :val="searchText" @input="changeSearch"></Search>
                </div> -->
                <div class="m-rows__row m-rows--fill">
                    <accordion ref="sourcesAcc" :single="true">
                        <accordion-entry v-for="source in sourceTree" :id="source.uid" :name="source.name" :icon="treeHelper.getSourceIcon(source.code)">
                            <template v-slot:header>
                                <div>{{source.name}}</div>
                            </template>
                            <template v-slot:content>
                                <div v-if="source.children">
                                    <Tree
                                        v-for="sourceEntry of source.children"
                                        :entry="sourceEntry"
                                        @toggle-node="toggleNode"
                                        @select-node="selectNode"
                                        :draggable="true"
                                        context="viewSiteSource"
                                    ></Tree>
                                </div>
                            </template>
                        </accordion-entry>
                    </accordion>
                </div>
            </div>
            <div class="u-padding u-align-center" v-else>
                <div class="m-loader"></div>
            </div>
        </template>
    </Window>
    <Modal :show="showSiteConfig" @close="showSiteConfig = false">
        <template v-slot:header><span class="u-light">{{ $t('config') }}</span> <span>{{ $t('site') }}</span> <b>{{$store.getters.nodeName}}</b></template>
        <template v-slot:content>demo</template>
    </Modal>
    <Modal :show="showNavigationAdd" @close="closeAddNavigation" :show-footer="true">
        <template v-slot:header>{{ $t('add-navigation') }}</template>
        <template v-slot:content>
            <Textbox :value="newNavigation.name" @input="changeNewNavigationName" @on-enter="addNavigation" :focus="showNavigationAdd"
                >{{ $t('name') }}</Textbox
            >
            <Textbox :value="normalize.toKebab(newNavigation.code)" @input="changeNewNavigationCode" icon="ri-magic-line" @on-enter="addNavigation"
                >{{ $t('code') }}</Textbox
            >
        </template>
        <template v-slot:footer>
            <Btn @click="closeAddNavigation">{{ $t('cancel') }}</Btn>
            <Btn @click="addNavigation" :primary="true">{{ $t('add') }}</Btn>
        </template>
    </Modal>
    <Modal :show="showDelete" @close="showDelete = false" :show-footer="true">
        <template v-slot:header><span class="u-light">{{ $t('delete') }}</span> <span>{{ $t('site') }}</span> <b>{{$store.getters.nodeName}}</b></template>
        <template v-slot:content>{{ $t('delete-question') }}</template>
        <template v-slot:footer>
            <Btn @click="showDelete = false">{{ $t('cancel') }}</Btn>
            <Btn @click="deleteSite" :primary="true">{{ $t('delete') }}</Btn>
        </template>
    </Modal>
</div>
