<div class="m-list-entry m-flag m-flag--rev" :class="{ 'm-list-entry--clickable': clickable }" @click="$emit('click')">
    <div class="m-list-entry__content m-flag__body">
        <div class="m-flag" v-if="icon">
            <div class="m-list-entry__icon m-flag__img" v-if="icon">
                <span v-if="isLoading" class="m-loader"></span>
                <i class="u-light u-text--size-x1_5" :class="icon" v-else></i>
            </div>
            <div class="m-list-entry__text m-flag__body"><slot name="content"></slot></div>
        </div>
        <div v-else class="m-list-entry__text"><slot name="content"></slot></div>
    </div>
    <div class="m-list-entry__button m-flag__img" v-if="showButton"><slot name="button"></slot></div>
</div>
