<div class="p-navigation u-fullsize">
    <Window reverse="true">
        <template v-slot:main>
            <div class="u-padding u-content">
                <Textbox :value="$store.getters.nodeName" @input="changeName" :focus="true">{{ $t('name') }}</Textbox>
                <Textbox :value="$store.getters.nodeCode" @input="changeCode" icon="ri-magic-line">{{ $t('code') }}</Textbox>
                <!-- <pre>{{JSON.stringify($store.state.node, null, 4)}}</pre> -->
                <div class="m-flag m-flag--rev u-padding-top">
                    <div class="m-flag__body m-label">{{ $t('entries') }}</div>
                    <div class="m-flag__img m-label">
                        <i class="ri-code-line u-cursor-pointer" :title="$i18n.t('show-code')" @click="openEntrySource"></i>
                    </div>
                </div>
                <div
                    :class="{'m-list-entry--avoid-child-events m-drag-clone--show': isDragging}"
                    @drop="releaseItem"
                    @mousemove="entryMove"
                    @mouseup="releaseItem"
                    @mouseleave="releaseItem"
                >
                    <Dropzone css-class="m-list-entry__container u-padding-top--x0" @drop="dropSource">
                        <div v-if="$store.getters.navigationItems.children.length == 0" class="u-align-center u-light u-padding">
                            {{ $t('no-navigation') }}
                        </div>
                        <ListEntryTree
                            ref="listEntryTree"
                            :entry="$store.getters.navigationItems"
                            :parents="entryParents"
                            :drag-uid="dragUid"
                            :drag-uniq="dragUniq"
                            @entry-drag="entryDrag"
                            @entry-edit="entryEdit"
                            @entry-update="entryUpdate"
                            @delete-question="showEntryDeleteQuestion"
                        ></ListEntryTree>
                    </Dropzone>
                    <div class="m-list-entry m-flag m-drag-clone" v-if="dragEntry" :style="dragMasterSettings">
                        <div class="m-flag__img">
                            <i class="ri-drag-move-line u-text--size-x2"></i>
                        </div>
                        <div class="m-flag__body">{{dragEntry.name}} <span class="u-light">{{dragEntry.code}}</span></div>
                    </div>
                </div>

                <div class="u-padding-top">
                    <Btn @click="showDelete = true">{{ $t('delete') }}</Btn>
                </div>
            </div>
        </template>
        <template v-slot:bar>
            <div class="m-rows" v-if="site">
                <!-- <div class="m-rows__row">
                        <Search :val="searchText" @input="changeSearch"></Search>
                    </div> -->
                <div class="m-rows__row m-rows--fill">
                    <accordion ref="sourcesAcc" :single="true" @mounted="initSourcesAccordion">
                        <accordion-entry v-for="source in sourceTree" :id="source.uid" :name="source.name" :icon="source.icon">
                            <template v-slot:header>
                                <div>{{source.name}}</div>
                                <div class="u-light  u-text--size-x_75">{{ getSourceParent(source.uid) }}</div>
                            </template>
                            <template v-slot:content>
                                <!-- <pre>{{JSON.stringify(source, null, 4)}}</pre> -->
                                <div v-if="source.children">
                                    <Tree
                                        v-for="sourceEntry of source.children"
                                        :entry="sourceEntry"
                                        @toggle-node="toggleNode"
                                        @select-node="selectNode"
                                        :draggable="true"
                                        context="viewNavigationSource"
                                    ></Tree>
                                </div>
                            </template>
                        </accordion-entry>
                    </accordion>
                </div>
            </div>
            <div class="u-padding u-align-center" v-else>
                <div class="m-loader"></div>
            </div>
        </template>
    </Window>
    <Modal :show="showEntrySource" @close="showEntrySource = false">
        <template v-slot:header
            ><span class="u-light">{{ $t('show-code') }}</span> <b>{{$store.getters.nodeName}}</b>
            <span class="u-light">{{ $store.getters.nodeCode }}</span></template
        >
        <template v-slot:content>
            <pre class="m-code" v-html="getEntrySourceCode"></pre>
        </template>
    </Modal>
    <Modal :show="showDelete" @close="showDelete = false" :show-footer="true">
        <template v-slot:header
            ><span class="u-light">{{ $t('delete') }}</span> <span>{{ $t('navigation') }}</span> <b>{{$store.getters.nodeName}}</b></template
        >
        <template v-slot:content>{{ $t('delete-question') }}</template>
        <template v-slot:footer>
            <Btn @click="showDelete = false">{{ $t('cancel') }}</Btn>
            <Btn @click="deleteNavigation" :primary="true">{{ $t('delete') }}</Btn>
        </template>
    </Modal>
    <Modal :show="showEntryDelete" @close="showEntryDelete = false" :show-footer="true">
        <template v-slot:header
            ><span class="u-light">{{ $t('delete') }}</span> <span>{{ $t('entry') }}</span> <b v-if="selectedEntry">{{selectedEntry.name}}</b></template
        >
        <template v-slot:content>{{ $t('delete-question') }}</template>
        <template v-slot:footer>
            <Btn @click="showEntryDelete = false">{{ $t('cancel') }}</Btn>
            <Btn @click="entryDeleteAllowed" :primary="true">{{ $t('delete') }}</Btn>
        </template>
    </Modal>
    <Modal :show="showEntryEdit" @close="showEntryEdit = false" :show-footer="true">
        <template v-slot:header
            ><span class="u-light">{{ $t('edit') }}</span> <span>{{ $t('entry') }}</span> <b v-if="selectedEntry">{{selectedEntry.name}}</b></template
        >
        <template v-slot:content>
            <div v-if="selectedEntry">
                <Textbox :value="selectedEntry.name" @input="changeSelectedEntryName" :focus="showEntryEdit" @on-enter="entryEditAction"
                    >{{ $t('name') }}</Textbox
                >
                <Textbox :value="selectedEntry.code" @input="changeSelectedEntryCode" icon="ri-magic-line" @on-enter="entryEditAction"
                    >{{ $t('code') }}</Textbox
                >
                <Textbox v-model="selectedEntry.identifier" @on-enter="entryEditAction">{{ $t('url') }}</Textbox>
                <div v-if="selectedEntry.target" class="u-padding-top">
                    <label class="m-label" for="entryTarget">{{ $t('open-mode') }}</label>
                    <div class="m-select">
                        <select v-model="selectedEntry.target" id="entryTarget">
                            <option :value="'_self'">{{ $t('target-self') }}</option>
                            <option :value="'_blank'">{{ $t('target-blank') }}</option>
                        </select>
                    </div>
                </div>
                <Textbox v-model="selectedEntry.id" @on-enter="entryEditAction">{{ $t('id') }}</Textbox>
                <Textbox v-model="selectedEntry.cssClass" @on-enter="entryEditAction">{{ $t('css-class') }}</Textbox>
            </div>
        </template>
        <template v-slot:footer>
            <Btn @click="showEntryEdit = false">{{ $t('cancel') }}</Btn>
            <Btn @click="entryEditAction" :primary="true">{{ $t('save') }}</Btn>
        </template>
    </Modal>
</div>
