<div class="c-accordion__entry" :class="{'c-accordion--open': open}">
    <div class="c-accordion__header m-flag m-flag--rev" @click="toggleOpen">
        <div class="m-flag__body">
            <div class="m-flag" v-if="icon">
                <div class="m-flag__img"><i :class="getIconClass"></i></div>
                <div class="m-flag__body">
                    <slot name="header"></slot>
                </div>
            </div>
            <div v-else="icon">
                <slot name="header"></slot>
            </div>
        </div>
        <div class="m-flag__img u-text--size-x1_5">
            <i :class="{'ri-arrow-down-s-fill': !open, 'ri-arrow-up-s-fill': open}"></i>
        </div>
    </div>
    <div class="c-accordion__content" v-show="open">
        <div class="c-accordion__content-inner">
            <slot name="content"></slot>
        </div>
    </div>
</div>
